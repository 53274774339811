<template>
    <div class="flex justify-center">
        <div>
            <vueSignature
            class="border"
            ref="signature"
            :sigOption="option"
            :w="'300px'"
            :h="'300px'"
            ></vueSignature>
            <div v-if="!isUploading" class="flex gap-2 mt-4 justify-center">
                <button class="px-4 py-2 bg-slate-100 rounded-xl" @click="clear">Recommencer</button>
                <button class="px-4 py-2 bg-sky-500 text-white rounded-xl" @click="save">Enregistrer</button>
            </div>
            <spinner :size="10" v-if="isUploading" />
        </div>
    </div>
</template>

<script>
import vueSignature from "vue-signature";

export default {
    components: {
        vueSignature,
    },
    data() {
        return {
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            isUploading: false,
        };
    },
    methods: {
        dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;
        },

        async save() {
            var pngBase64 = this.$refs.signature.save();
            const file = this.dataURItoBlob(pngBase64)
            var bodyFormData = new FormData();
            bodyFormData.append('file', file);
            bodyFormData.append('key', 'signature');

            this.isUploading = true
            this.fileUrl = null
            let response = await this.$request.post('/file/add', bodyFormData)
            if (response.data.success == 1) {
                this.$emit('input', response.data.api.url)
            } else {
                this.$emit('Une erreur est survenue')
            }
            this.isUploading = false
        },
        clear() {
            this.$refs.signature.clear();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>