<template>
    <div>
        <app-template :header-dark="false" title="Signature">
            <template v-slot:mobile>
                <div class="px-4">
                    <div class="bg-white rounded-2xl p-3 pt-4 pb-8 mb-3">
                        <div class="font-bold mb-2">Ajouter une signature</div>
                        <div class="mb-1 text-sm">Vous devez créer une signature pour pouvoir continuer.</div>
                        <div class="text-sm">Cette signature sera utilisée sur les fiches générées par le site afin de les signer automatiquement.</div>
                    </div>

                    <div class="bg-white rounded-2xl p-3 pt-4 pb-4 mb-3">
                        <div v-if="!$account.signature" class="mb-3">
                            <div class="p-4 mb-2 text-sm text-yellow-800 rounded-2xl bg-yellow-50" role="alert">
                                <span class="font-medium">Attention: </span> Une fois votre signature ajoutée, elle ne pourra plus être modifée
                                <hr class="my-2 border-yellow-800" />
                                Pensez à vérifier que la signature ne dépasse pas du cadre
                            </div>
                            <signature @input="uploadSignature" v-model="signature" />
                        </div>
                        <div v-if="$account.signature">
                            <img class="border rounded-lg h-[150px]" :src="$account.signature" />
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import Signature from '../../components/Signature.vue'
export default {
    components: {
        Signature,
    },
    data() {
        return {
            signature: null
        }
    },
    methods: {
        async uploadSignature() {
            let response = await this.$request.post('/employe/add-signature', {
                signature: this.signature
            })
            if (response.data.api == 1) {
                let response = await this.$request.post('/auth/account')
                if (response.data.success) {
                    localStorage.setItem('auth', JSON.stringify(response.data.api))
                }
                window.location = '/'
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>