<template>
    <div>
        <div class="relative">
            <div v-if="manual" class="flex items-center justify-center gap-2 mb-2">
                <button @click="manualSearch = 0" class="px-4 py-2 bg-gray-100 text-xs rounded rounded">Automatique</button>
                <button @click="manualSearch = 1" class="px-4 py-2 bg-gray-100 text-xs rounded rounded">Manuelle</button>
            </div>
            <template v-if="!manualSearch">
                <input-rule v-model="search" @search="searchAddresses" type="vselect" :options="{values: addresses, label: 'complet', filterable: false}" label="Adresse" />
            </template>
            <template v-if="manualSearch && model">
                <div class="grid grid-cols-2 gap-2 mb-2">
                    <input-rule v-model="model.numero" type="text" label="Numéro" />
                    <input-rule v-model="model.rue" type="text" label="Rue" />
                </div>
                <div class="grid grid-cols-2 gap-2 mb-2">
                    <input-rule v-model="model.cp" type="text" label="Cp" />
                    <input-rule v-model="model.ville" type="text" label="Ville" />
                </div>
                <input-rule v-model="model.pays" type="text" label="Pays" />
            </template>
            <!-- <div v-if="addresses && addresses.length > 0" class="absolute left-0 right-0 bg-white border border-t-0 rounded-b shadow-sm p-2">
                <div @click="selectAddress(address)" v-for="(address, addressIndex) in addresses" :key="'address-' + addressIndex" :class="addressIndex > 0 ? 'border-t' : ''" class="pb-3 pt-3">
                    {{ address.complet }}
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        manual: {default: true},
        default: {},
        autoReset: {default: false}
    },
    watch: {
        default() {
            if (this.default) {
                this.searchDefault = true
                this.search = this.default
            }
        },
        search(value) {
            this.selectAddress(value)
        }
    },
    data() {
        return {
            search: null,
            timer: null,
            model: null,
            addresses: [],
            manualSearch: false,
            search: null,
            searchDefault: false,
        }
    },
    methods: {
        initModel() {
            this.model = {
                numero: null,
                rue: null,
                cp: null,
                ville: null,
                region: null,
                pays: null,
                pays_code: null,
                longitude: null,
                latitude: null,
            }
        },
        searchAddresses(inp) {
            if ((inp && (!this.model.complet || inp.toLowerCase() != this.model.complet.toLowerCase())) || this.searchDefault) {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    let response = await axios.get('https://api.geoapify.com/v1/geocode/autocomplete?text=' + encodeURI(inp) + '&apiKey=4103671ddca2476cb5508be672f1b276')
                    if (response.data.features) {
                        this.addresses = []
                        response.data.features.forEach((address) => {
                            this.addresses.push({
                                numero: address.properties.housenumber,
                                rue: address.properties.street,
                                cp: address.properties.postcode,
                                ville: address.properties.city,
                                region: address.properties.state,
                                pays: address.properties.country,
                                pays_code: address.properties.country_code,
                                longitude: address.geometry.coordinates[0],
                                latitude: address.geometry.coordinates[1],
                                complet: (address.properties.housenumber + ' ' + address.properties.street + ', ' + address.properties.postcode + ' ' + address.properties.city + ', ' + address.properties.country).trim()
                            })
                        })
                        if (this.searchDefault) {
                            this.selectAddress(this.addresses[0])
                            this.searchDefault = false
                        }
                    }
                    this.$forceUpdate()
                }, 500)
            } else {
                this.initModel()
            }
        },

        selectAddress(address) {
            this.model = address
            this.addresses = []
            if (!this.model.complet) {
                this.model.complet = (this.model.numero + ' ' + this.model.rue + ', ' + this.model.cp + ' ' + this.model.ville + ', ' + this.model.pays).trim()
            }
            this.search = address.complet
            this.$emit('input', this.model)
            if (this.autoReset) {
                this.search = null
                this.initModel()
            }
        }
    },
    mounted () {
        this.initModel();
        if (this.default) {
            this.searchDefault = true
            this.search = this.default
        }
    },
}
</script>

<style lang="scss" scoped>

</style>