<template>
    <div>
        <div v-if="displayMobileMenu" @click="displayMobileMenu = false" class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-90" style="z-index: 21">
            <div @click.stop="" class="fixed top-0 bottom-0 left-0 w-[70%] bg-white p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-6 mb-6">
                    <img class="h-[45px]" v-if="$account.societe.logo" :src="$account.societe.logo" />
                    <h2 v-else class="text-lg font-black mt-1">{{ $account.societe.nom }}</h2>
                    <button @click="displayMobileMenu = false"><i class="fas fa-times text-red-500 text-2xl"></i></button>
                </div>
                <div @click="$router.push({name: 'home'})" class="mb-6 text-2xl" :class="$route.name == 'home' || $route.name == 'fiche' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Accueil</div>
                </div>
                <div @click="$router.push({name: 'new-contract'})" class="mb-6 text-2xl" :class="$route.name == 'new-contract' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Créer une fiche</div>
                </div>
                <div @click="$router.push({name: 'solde'})" class="mb-6 text-2xl" :class="$route.name == 'solde' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Solde</div>
                </div>
                <div @click="$router.push({name: 'clients'})" class="mb-6 text-2xl" :class="$route.name == 'clients' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Clients</div>
                </div>
                <div @click="$router.push({name: 'account'})" class="mb-6 text-2xl" :class="$route.name == 'account' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Compte</div>
                </div>
                <div @click="$router.push({name: 'entreprise'})" class="mb-6 text-2xl" :class="$route.name == 'entreprise' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Entreprise</div>
                </div>
                <div @click="$router.push({name: 'support'})" class="mb-6 text-2xl" :class="$route.name == 'support' ? 'opacity-100' : 'opacity-40'">
                    <div class="font-light">Support</div>
                </div>
            </div>
        </div>
        <div class="md:hidden">
            <div class="fixed z-20 top-0 py-2 px-4 z-19 left-0 right-0 flex items-center gap-4 justify-between" :style="headerDark ? 'background: #161822; color: white;' : 'background: #ecf1f4'">
                <div class="flex items-center gap-2">
                    <div class="h-[35px] w-[35px] rounded-full" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + $account.avatar + ')'"></div>
                    <div>
                        <div class="font-light text-xs mt-1">Bonjour {{ $account.prenom }}</div>
                        <div class="font-semibold -mt-0.5">{{ title }}</div>
                    </div>
                </div>
                <button @click="displayMobileMenu = true"><i class="fas fa-bars text-xl"></i></button>
            </div>

            <div class="pb-[80px] mt-16">
                <!-- <img class="h-[20px] mx-auto mb-4" :src="$account.societe.logo" alt="logo"> -->

                <slot name="mobile"></slot>
            </div>
        </div>
        <div class="hidden md:block">
            <template v-if="$slots.largeScreen">
                <div class="fixed top-0 left-0 bottom-0 w-[236px] bg-white text-left">
                    <div class="flex items-center justify-start text-left gap-2 p-2 py-6">
                        <img class="w-[35px] h-[35px] rounded-full" :src="$account.avatar" />
                        <div>
                            <div>{{ $account.nom_complet }}</div>
                            <div class="text-xs font-light">{{ $account.email }}</div>
                        </div>
                    </div>
                    <div class="px-2">
                        <div @click="$router.push({name: 'home'})" class="py-3 px-4 rounded-lg cursor-pointer mb-2 bg-blue-50 font-bold">
                            <i class="far fa-calendar-days mr-1"></i> Missions
                        </div>
                        <div class="py-3 px-4 rounded-lg cursor-pointer mb-2 hover:bg-gray-100 duration-200">
                            <i class="fas fa-users mr-1"></i> Clients
                        </div>
                        <div class="py-3 px-4 rounded-lg cursor-pointer mb-2 hover:bg-gray-100 duration-200">
                            <i class="far fa-building mr-1"></i> Entreprise
                        </div>
                        <div class="py-3 px-4 rounded-lg cursor-pointer mb-2 hover:bg-gray-100 duration-200">
                            <i class="fas fa-euro mr-1"></i> Facturation
                        </div>
                        <div class="py-3 px-4 rounded-lg cursor-pointer mb-2 hover:bg-gray-100 duration-200">
                            <i class="far fa-user mr-1"></i> Mon compte
                        </div>
                    </div>
                </div>
                <div class="bg-gray-50 fixed top-0 left-[236px] right-0 bottom-0 overflow-y-auto p-4">
                    <slot name="largeScreen"></slot>
                </div>
            </template>
            <template v-else>
                <div class="flex items-center h-[100vh] justify-center">
                    <h3 class="text-3xl text-center font-bold w-1/2">
                        Cette page est accessible uniquement sur téléphone et tablette
                    </h3>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        displayNavbar: {
            default: true
        },
        headerDark: {default: false},
        title: {}
    },
    data() {
        return {
            displayMobileMenu: false
        }
    },
}
</script>

<style>
.vm--overlay {
    background: rgba(0, 0, 0, 0.7) !important;
}
</style>