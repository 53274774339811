<template>
    <div>
        <div class="px-2.5 py-1.5 text-xs rounded-full font-semibold" :class="bgColor">{{ label }}</div>
    </div>
</template>

<script>
export default {
    props: {
        state: {
            required: true
        },
    },
    computed: {
        bgColor() {
            switch (this.state) {
                case 0:
                    return 'bg-slate-200'
                case 1:
                    return 'bg-orange-200'
                case 2:
                    return 'bg-green-200'
                default:
                    return 'bg-slate-200'
            } 
        },
        label() {
            switch (this.state) {
                case 0:
                    return 'Planifiée'
                case 1:
                    return 'En cours'
                case 2:
                    return 'Terminée'
                default:
                    return 'Erreur'
            } 
        }
    },
}
</script>

<style lang="scss" scoped>

</style>