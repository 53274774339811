<template>
    <div>
        <modal name="modal-edit-add-address" width="100%" height="500px" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Ajouter une adresse</h2>
                    <button @click="$modal.hide('modal-edit-add-address')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <address-input v-model="newAddress" type="text" class="mb-3" />
                    <button @click="createAddress" class="text-white rounded bg-sky-500 px-4 py-2 mt-2 mb-3">Ajouter l'adresse <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </modal>

        <modal name="modal-view-doc" width="100%" height="500px" :scrollable="true">
            <div v-if="selectedDoc" class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Consulter un document</h2>
                    <button @click="$modal.hide('modal-view-doc')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <iframe :src="selectedDoc.url" class="mb-8" frameborder="0" width="100%" height="250px"></iframe>
                    <div class="text-center">
                        <a :href="selectedDoc.url" target="_blank" class="px-4 py-2 mt-10 bg-gray-100 rounded">Ouvrir dans un nouvel onglet</a>
                    </div>
                </div>
            </div>
        </modal>

        <div class="bg-white rounded shadow p-4">
            <div v-show="step == 'vehicule'">
                <!-- Informations véhicule -->
                <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-car mr-1"></i> Véhicule</h2>
                <div class="mt-2 mb-4">
                    <div class="mb-1">Photo du véhicule</div>
                    <input-rule @input="(value) => {form.image_default = value ? value.url : null}" type="file" id="image" :options="{fileType: 'image/*', key: 'voitures'}" />
                </div>
                <div class="mt-2 mb-4">
                    <div class="mb-1">Marque</div>
                    <input-rule v-model="selectedMarque" type="vselect" :options="{values: marques}" id="voiture" />
                </div>
                <div class="mt-2 mb-4">
                    <div class="mb-1">Modèle</div>
                    <input-rule v-model="selectedModele" @input="selectModele" type="vselect" :options="{values: models, label: 'modele', key: 'id'}" id="voiture" />
                </div>
                <div class="mt-2 mb-4">
                    <div class="mb-1">Immatriculation</div>
                    <input-rule v-model="form.immatriculation" :options="{uppercase: true}" type="text" id="immatriculation" />
                </div>
                <div class="mt-2 mb-4">
                    <div class="mb-1">Num. châssis</div>
                    <input-rule v-model="form.chassis" :options="{uppercase: true}" type="text" id="chassis" />
                </div>

                <div class="text-center border-t-2 pt-5 mt-8">
                    <button @click="nextStep('itineraire')" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Suivant <i class="fas fa-arrow-right ml-1"></i></button>
                </div>
            </div>

            <div v-show="step == 'itineraire'">
                <div class="border-b-2 pb-8 mb-8">
                    <!-- Informations client depart -->
                    <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-flag mr-1"></i> Départ</h2>
                    <div class="mb-2">
                        <!-- <div>Client</div> -->
                        <client-input @input="(data) => {form.clientDepartId = data.id; getDistance(); getAdresseClient('depart')}" id="client-depart" />
                    </div>
                    <div v-if="form.clientDepartId" class="mt-4">
                        <div class="mb-1">Séléctionner une adresse</div>
                        <button @click="modalAddAdresseType ='depart'; $modal.show('modal-edit-add-address')" class="px-4 py-2 bg-slate-100 rounded text-sm mb-2"><i class="fas fa-plus mr-1"></i> Ajouter une adresse</button>

                        <div @click="form.clientDepartAddressId = adresse.id; getDistance()" v-for="(adresse, adresseIndex) in departAdresses" :key="'adresse-depart-' + adresseIndex" class="mb-2 border p-3 rounded flex items-center gap-2 hover:bg-gray-100 duration-200 cursor-pointer">
                            <template v-if="form.clientDepartAddressId == adresse.id">
                                <i class="fas fa-check-circle text-green-500"></i>
                            </template>
                            <template v-else>
                                <div class="w-[16px] h-[16px] bg-slate-200 rounded-full"></div>
                            </template>
                            <div>{{ adresse.complet }}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <!-- Informations client arrivee -->
                    <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-flag-checkered mr-1"></i> Arrivée</h2>
                    <div class="mb-4">
                        <!-- <div>Client</div> -->
                        <client-input @input="(data) => {form.clientArriveeId = data.id; getDistance(); getAdresseClient('arrivee')}" v-model="clientAdresseArrivee" id="client-arrivee" />
                    </div>
                    <div v-if="form.clientArriveeId" class="mt-4">
                        <div class="mb-1">Séléctionner une adresse</div>
                        <button @click="modalAddAdresseType ='arrivee'; $modal.show('modal-edit-add-address')" class="px-4 py-2 bg-slate-100 rounded text-sm mb-2"><i class="fas fa-plus mr-1"></i> Ajouter une adresse</button>
                        <div @click="form.clientArriveeAddressId = adresse.id; getDistance()" v-for="(adresse, adresseIndex) in arriveeAdresses" :key="'adresse-arrivee-' + adresseIndex" class="mb-2 border p-3 rounded flex items-center gap-2 hover:bg-gray-100 duration-200 cursor-pointer">
                            <template v-if="form.clientArriveeAddressId == adresse.id">
                                <i class="fas fa-check-circle text-green-500"></i>
                            </template>
                            <template v-else>
                                <div class="w-[16px] h-[16px] bg-slate-200 rounded-full"></div>
                            </template>
                            <div>{{ adresse.complet }}</div>
                        </div>
                    </div>
                </div>

                <div class="text-center border-t-2 pt-5 mt-8 flex justify-center gap-2">
                    <button @click="step = 'vehicule'" class="text-slate-700 rounded-3xl px-6 py-3 mb-2 bg-slate-200"><i class="fas fa-arrow-left mr-1"></i> Précédent</button>
                    <button @click="nextStep(employes.length > 1 ? 'employes' : 'options')" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Suivant <i class="fas fa-arrow-right ml-1"></i></button>
                </div>
            </div>

            <div v-show="step == 'employes'">
                <!-- Employé -->
                <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-user mr-1"></i> Employés</h2>
                <div class="grid grid-cols-2 gap-3 items-center mt-4">
                    <div class="flex items-center gap-4">
                        Donneur d'ordre
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <div>
                            <input-rule v-model="form.donneur_ordre_id" type="select" :options="{values: employes, label: 'nom_complet', key: 'id'}" class="block text-sm font-medium text-gray-900" />
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Conducteur
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <div v-if="employes && employes.length > 0">
                            <input-rule v-model="form.employe_id" type="select" :options="{values: employes, label: 'nom_complet', key: 'id'}" class="block text-sm font-medium text-gray-900" />
                        </div>
                    </div>
                </div>

                <div class="text-center border-t-2 pt-5 mt-8 flex justify-center gap-2">
                    <button @click="step = 'itineraire'" class="text-slate-700 rounded-3xl px-6 py-3 mb-2 bg-slate-200"><i class="fas fa-arrow-left mr-1"></i> Précédent</button>
                    <button @click="nextStep('options')" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Suivant <i class="fas fa-arrow-right ml-1"></i></button>
                </div>
            </div>

            <div v-show="step == 'options'">
                <!-- Configuration -->
                <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-cog mr-1"></i> Options</h2>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div>
                        Transport par camion
                    </div>
                    <div>
                        <input-rule v-model="form.transportCamion" type="pcheck" id="camion" class="mt-0.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none p-2" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div>
                        Gestion de la jauge de carburant
                    </div>
                    <div>
                        <input-rule v-model="form.gestionJauge" type="pcheck" id="gestion_carburant" class="mt-0.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none p-2" />
                    </div>
                </div>

                <div class="text-center border-t-2 pt-5 mt-8 flex justify-center gap-2">
                    <button @click="step = employes.length > 1 ? 'employes' : 'itineraire'" class="text-slate-700 rounded-3xl px-6 py-3 mb-2 bg-slate-200"><i class="fas fa-arrow-left mr-1"></i> Précédent</button>
                    <button @click="nextStep('documents')" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Suivant <i class="fas fa-arrow-right ml-1"></i></button>
                </div>
            </div>

            <div v-show="step == 'documents'">
                <!-- Documents -->
                <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-file mr-1"></i> Documents</h2>
                <input-rule class="mb-2" v-model="typeDoc" type="vselect" :options="{values: ['Devis', 'Facture', 'Contrat', 'Autre']}" />
                <input-rule @input="(value) => {if (value) {form.documents.push({url: value.url, type: typeDoc ? typeDoc : 'Autre'}); typeDoc = 'devis'}}" type="file" id="image" :options="{fileType: '*', key: 'fiche_documents', autoreset: true}" />
                <div class="flex items-center justify-between mt-3 gap-4" v-for="(doc, docIndex) in form.documents" :key="'doc-' + docIndex">
                    <div>{{ doc.type }}</div>
                    <div class="grow border-b border-dashed"></div>
                    <div class="flex items-center gap-2">
                        <button class="bg-red-200 rounded-lg px-3.5 py-1.5" @click="form.documents.splice(docIndex, 1)"><i class="fas fa-trash"></i></button>
                        <button class="bg-gray-200 rounded-lg px-3.5 py-1.5" @click="selectedDoc = doc; $modal.show('modal-view-doc')"><i class="fas fa-eye"></i></button>
                    </div>
                </div>

                <div class="text-center border-t-2 pt-5 mt-8 flex justify-center gap-2">
                    <button @click="step = 'options'" class="text-slate-700 rounded-3xl px-6 py-3 mb-2 bg-slate-200"><i class="fas fa-arrow-left mr-1"></i></button>
                    <button @click="createMission" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Créer la fiche <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>

            <div v-show="step == 'devis'">
                <!-- Tarification -->
                <h2 class="font-bold text-lg pb-3 mb-3 border-b-2"><i class="fas fa-euro mr-1"></i> Tarification</h2>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Prix kilomètre
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <input-rule v-model="form.prix_km" type="number" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Distance (km)
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <input-rule v-model="form.distance" type="number" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Coût péage
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <input-rule v-model="form.prix_peage" type="number" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Coût carburant
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <input-rule v-model="form.prix_carburant" type="number" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Remise (%)
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <input-rule v-model="form.remise" type="number" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Prix HT
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        <template v-if="prixHt">{{ prixHt }} €</template>
                        <template v-else>0 €</template>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 items-center mt-2">
                    <div class="flex items-center gap-4">
                        Prix TTC
                        <div class="border-b grow border-dashed border-slate-300"></div>
                    </div>
                    <div>
                        {{ prixTtc }} €
                    </div>
                </div>

                <div class="text-center border-t-2 pt-5 mt-8">
                    <button @click="createMission" class="text-white rounded-3xl px-6 py-3 mb-2 bg-sky-500">Créer la fiche <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientInput from './ClientInput.vue'
import AddressClientInput from './AddressClientInput.vue'

export default {
    components: {
        AddressClientInput,
        ClientInput,
    },
    watch: {
        selectedMarque() {
            this.getModels()
        }
    },
    data() {
        return {
            employes: [],
            marques: [],
            models: [],
            selectedMarque: null,
            selectedModele: null,
            step: 'vehicule',
            form: {
                employe_id: null,
                selectedVoiture: null,
                immatriculation: null,
                chassis: null,
                clientDepartId: null,
                clientDepartAddressId: null,
                clientArriveeId: null,
                clientArriveeAddressId: null,
                transportCamion: false,
                gestionJauge: false,
                image_default: null,
                documents: []
            },
            newClient: {
                societe: 0,
                nom: null,
                prenom: null,
                tel: null,
            },
            typeDoc: 'devis',
            selectedDoc: null,
            clientAdresseDepart: null,
            clientAdresseArrivee: null,
            departAdresses: [],
            arriveeAdresses: [],
            departArrivee: [],
            newAddress: null,
            modalAddAdresseType: null,
        }
    },

    computed: {
        prixHt() {
            let prixKm = parseFloat(this.form.prix_km) ? parseFloat(this.form.prix_km) : 0
            let distance = parseFloat(this.form.distance) ? parseFloat(this.form.distance) : 0
            let peage = parseFloat(this.form.prix_peage) ? parseFloat(this.form.prix_peage) : 0
            let carburant = parseFloat(this.form.prix_carburant) ? parseFloat(this.form.prix_carburant) : 0
            let price = (prixKm * distance) + peage + carburant
            if (this.form.remise && this.form.remise != 0) {
                price -= parseFloat(this.form.remise) / 100 * price
            }
            return parseFloat(price).toFixed(2)
        },
        prixTtc() {
            let price = parseFloat(this.prixHt) + (parseFloat(this.prixHt) * 20/100)
            return parseFloat(price).toFixed(2)
        }
    },

    methods: {
        async getEmployes() {
            let response = await this.$request.get('/employe/list')
            this.employes = response.data.api
            console.log(this.employes);
            this.form.employe_id = this.employes[0].id
            this.form.donneur_ordre_id = this.employes.find((item) => item.id == this.$account.id).id ? this.employes.find((item) => item.id == this.$account.id).id : this.employes[0].id
        },

        async getMarques() {
            let response = await this.$request.get('/voiture/marque/list')
            this.marques = response.data.api
            this.selectedMarque = this.marques[0]
        },

        async getModels() {
            let response = await this.$request.get('/voiture/model/list?marque=' + this.selectedMarque)
            this.models = response.data.api
            this.form.selectedVoiture = this.models[0].id
        },

        selectModele() {
            if (this.selectedModele) {
                this.form.selectedVoiture = this.selectedModele.id
            }
        },

        async createMission() {
            this.form.prix_ht = this.prixHt
            this.form.prix = this.prixTtc
            let response = await this.$request.post('/mission/add', this.form)
            if (response.data.success) {
                this.$toast.success('Fiche créée')
                this.$router.push({name: 'mission', params: {id: response.data.api.id}})
            }
        },

        async getAdresseClient(type) {
            let response = await this.$request.post('/adresse/search', {
                client_id: type == 'depart' ? this.form.clientDepartId : this.form.clientArriveeId,
                search: this.search
            })
            if (type == 'depart') {
                this.departAdresses = response.data.api
                if (this.departAdresses.length == 1) {
                    this.clientAdresseDepart = this.departAdresses[0]
                    this.form.clientDepartAddressId = this.departAdresses[0].id
                    this.getDistance()
                }
            } else {
                this.arriveeAdresses = response.data.api
                if (this.arriveeAdresses.length == 1) {
                    this.clientAdresseArrivee = this.arriveeAdresses[0]
                    this.form.clientArriveeAddressId = this.arriveeAdresses[0].id
                    this.getDistance()
                }
            }
        },

        async getDistance() {
            if (!this.clientAdresseDepart || !this.clientAdresseArrivee) {
                return
            }
            // let response = await axios.post('https://api.geoapify.com/v1/routeplanner?apiKey=4103671ddca2476cb5508be672f1b276', {
            //     "mode": "drive",
            //     "traffic": "approximated",
            //     "agents": [
            //         {"start_location": [this.clientAdresseDepart.longitude, this.clientAdresseDepart.latitude]},
            //     ],
            //     "jobs": [
            //         {"location": [this.clientAdresseArrivee.longitude, this.clientAdresseArrivee.latitude]},
            //     ],
            // })
            // this.form.distance = (response.data.features[0].properties.distance / 1000)
        },

        async createAddress() {
            let endObj = {...this.newAddress}
            if (this.modalAddAdresseType == 'depart') {
                endObj.client_id = this.form.clientDepartId
            } else {
                endObj.client_id = this.form.clientArriveeId
            }
            let response = await this.$request.post('/adresse/edit-add', endObj)
            if (response.data.success) {
                await this.getAdresseClient(this.modalAddAdresseType)
                this.$modal.hide('modal-edit-add-address')
                if (this.modalAddAdresseType == 'depart') {
                    this.form.clientDepartAddressId = response.data.api.id
                } else {
                    this.form.clientArriveeAddressId = response.data.api.id
                }
            } else {
                this.$toast.error('Une erreur est survenue')
            }
        },

        nextStep(step) {
            if (step == 'itineraire') {
                if (!this.$validation.check([
                    {value: this.form.selectedVoiture, rules: 'required', id: 'voiture'},
                    {value: this.form.immatriculation, rules: 'required', id: 'immatriculation'},
                    {value: this.form.chassis, rules: 'required', id: 'chassis'},
                ])) {return}
            }
            if (step == 'options') {
                if (!this.$validation.check([
                    {value: this.form.clientDepartId, rules: 'required', id: 'client-depart'},
                    {value: this.form.clientArriveeId, rules: 'required', id: 'client-arrivee'},
                ])) {return}
                if (!this.form.clientDepartAddressId) {
                    this.$toast.error('Adresse de départ obligatoire')
                    return
                }
                if (!this.form.clientArriveeAddressId) {
                    this.$toast.error("Adresse d'arrivée obligatoire")
                    return
                }
            }
            console.log(step);
            this.step = step
        }
    },

    mounted () {
        this.getEmployes()
        this.getMarques()
        this.form.prix_km = this.$account.societe.tarif_km
    },
}
</script>

<style lang="scss" scoped>

</style>