<template>
    <div>
        <modal :name="'modal-add-default-' + _uid" height="auto" width="98%" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">
                        <span v-if="!dataId">Ajouter un défaut</span>
                        <span v-if="dataId">Modifier un défaut</span>
                    </h2>
                    <button @click="$modal.hide('modal-add-default-' + _uid)"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mt-3 max-h-[70vh] overflow-y-auto">
                    <div class="mb-3">
                        <label for="marque" class="block mb-0.5 text-sm font-medium text-gray-900">Partie du véhicule</label>
                        <select @change="form.emplacement_x = null; form.emplacement_y = null" v-model="form.face" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                            <option value="1">Face avant</option>
                            <option value="2">Face arrière</option>
                            <option value="3">Côté gauche</option>
                            <option value="4">Côté droit</option>
                            <option value="5">Toit</option>
                            <option value="6">Habitacle</option>
                            <option value="7">Autre</option>
                        </select>
                    </div>
                    <div v-if="form.face && form.face != 6 && form.face != 7" class="mb-3">
                        <label class="block text-sm font-medium text-gray-900">Emplacement du défaut</label>
                        <label class="block mb-0.5 text-sm font-thin text-gray-900">Toucher à l'endroit désiré</label>
                        <div id="draw-defaut" class="w-[300px] h-[300px] relative">
                            <img @click="clickOnImage" class="w-[300px] h-[300px] border" :src="faceImgUrl" alt="">
                            <div v-if="form.emplacement_x && form.emplacement_y" class="h-[40px] w-[40px] rounded-full absolute bg-red-300 border-2 border-red-600 -ml-[20px] -mt-[20px]" :style="'top: ' + form.emplacement_y + 'px; left: ' + form.emplacement_x + 'px;'"></div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="marque" class="block mb-0.5 text-sm font-medium text-gray-900">Description</label>
                        <div class="my-1">
                            <div class="text-xs font-light mb-1">Raccourcis:</div>
                            <div class="flex gap-2">
                                <button @click="form.description = 'Eclat'" class="bg-gray-100 text-sm px-2.5 py-1.5 rounded">Eclat</button>
                                <button @click="form.description = 'Rayure'" class="bg-gray-100 text-sm px-2.5 py-1.5 rounded">Rayure</button>
                                <button @click="form.description = 'Impact'" class="bg-gray-100 text-sm px-2.5 py-1.5 rounded">Impact</button>
                                <button @click="form.description = 'Disfonctionnement'" class="bg-gray-100 text-sm px-2.5 py-1.5 rounded">Disfonctionnement</button>
                            </div>
                        </div>
                        <textarea v-model="form.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2" rows="2"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="block mb-0.5 text-sm font-medium text-gray-900">Photos</label>
                        <input-rule @input="addImage" type="file" :options="{fileType: 'image/*', preview: false, key: 'defauts', autoreset: true}" />
                    </div>
                    <div class="mb-3">
                        <div class="grid grid-cols-2 gap-2">
                            <div v-for="(imgUrl, imgIndex) in form.images" :key="'defaut-img-' + imgIndex" class="border rounded-lg text-center">
                                <img class="w-full rounded-t-lg" :src="imgUrl">
                                <button @click="deleteImg(imgUrl)" class="bg-red-500 text-white text-sm rounded-b-lg w-full px-4 py-2"><i class="fas fa-times mr-0.5"></i> Supprimer</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5 border-t pt-4 mt-4">
                        <button :disabled="loadingAddDefaut" @click="addDefault" class="bg-green-500 text-white rounded px-6 py-3">
                            <spinner v-if="loadingAddDefaut" :size="10" />
                            <template v-if="!loadingAddDefaut">
                                <template v-if="!dataId">
                                    Ajouter <i class="fas fa-plus ml-1"></i>
                                </template>
                                <template v-if="dataId">
                                    Modifier <i class="fas fa-pen ml-1"></i>
                                </template>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal :name="'modal-crop-image-' + _uid" height="auto" width="98%" :scrollable="true">
            <cropper
            :src="imgBeforeCropUrl"
            :stencil-size="{width: 330, height: 330}"
            @change="changeHandler"
            ></cropper>
            <div class="flex gap-4 justify-center py-4">
                <button @click="$modal.hide('modal-crop-image-' + _uid)" class="px-4 py-2 bg-slate-200 rounded">
                    Annuler
                </button>
                <button :disabled="loadingAddCropImg" @click="addCropImage" class="px-4 py-2 bg-green-500 text-white rounded">
                    <spinner v-if="loadingAddCropImg" :size="10" />
                    <template v-if="!loadingAddCropImg">
                        Ajouter <i class="fas fa-plus ml-0.5"></i>
                    </template>
                </button>
            </div>
        </modal>

        <button @click="$modal.show('modal-add-default-' + _uid)" class="px-4 py-2 bg-orange-300 rounded text-orange-900 text-sm">Ajouter un défaut <i class="fas fa-plus ml-1"></i></button>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Compressor from 'compressorjs';

export default {
    props: {
        mission: {required: true},
        type: {required: true},
        dataId: {},
    },
    watch: {
        dataId() {
            this.initData()
        }
    },
    data() {
        return {
            form: {},
            imgBeforeCropUrl: null,
            croppedCanva: null,
            loadingAddCropImg: false,
            loadingAddDefaut: false,
        }
    },

    computed: {
        faceImgUrl() {
            if (this.form.face == 1) {
                return 'https://i.ibb.co/bK9dmMc/Nouveau-projet-50.png'
            }
            if (this.form.face == 2) {
                return 'https://i.ibb.co/3p9pPwv/Nouveau-projet-51.png'
            }
            if (this.form.face == 3) {
                return 'https://i.ibb.co/1r1BqGY/Nouveau-projet-48.png'
            }
            if (this.form.face == 4) {
                return 'https://i.ibb.co/rp6PCzX/Nouveau-projet-49.png'
            }
            if (this.form.face == 5) {
                return 'https://i.ibb.co/7R1QBzt/Nouveau-projet-52.png'
            }
        }
    },

    methods: {
        async initData() {
            if (this.dataId) {
                let response = await this.$request.get('/defaut/get?id=' + this.dataId)
                let defaultValue = response.data.api
                this.form = {
                    defaut_id: defaultValue.id,
                    face: defaultValue.face,
                    description: defaultValue.description,
                    emplacement_img: defaultValue.emplacement_img,
                    images: defaultValue.images.map((item) => { return item.url })
                }
            } else {
                this.form = {
                    defaut_id: null,
                    face: 1,
                    emplacement_x: 0,
                    emplacement_y: 0,
                    emplacement_img: null,
                    description: null,
                    images: []
                }
            }
            this.form.mission_id = this.mission.id
            this.form.type = this.type
        },

        async addDefault() {
            // Upload image emplacement
            this.loadingAddDefaut = true
            let imageEmplacement = await this.convertDraw()
            var bodyFormData = new FormData();
            bodyFormData.append('file', imageEmplacement);
            bodyFormData.append('key', 'default-cropped');
            let responseEmplacement = await this.$request.post('/file/add', bodyFormData)
            this.form.emplacement_img = responseEmplacement.data.api.url

            let response = await this.$request.post('/defaut/edit-add', this.form)
            if (response.data.api.id) {
                this.$modal.hide('modal-add-default-' + this._uid)
                this.$emit('added')
            }
            await this.initData()
            this.loadingAddDefaut = false
        },

        addImage(image) {
            if (image && image.url) {
                this.imgBeforeCropUrl = image.url
                this.$modal.show('modal-crop-image-' + this._uid)
            }
        },

        compressFile(file) {
            if (!file) {
                return;
            }

            new Compressor(file, {
                quality: 0.5,
                convertSize: 0,
                convertTypes: ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'],
                success: async (result) => {
                    if (result.size < 5000000) {
                        try {
                            var bodyFormData = new FormData();
                            bodyFormData.append('file', file);
                            bodyFormData.append('key', 'test');
                
                            this.fileUrl = null
                            let response = await this.$request.post('/file/add', bodyFormData)
                            this.form.images.push(response.data.api.url)
                            this.$modal.hide('modal-crop-image-' + this._uid)
                            this.loadingAddCropImg = false
                        } catch (error) {
                            console.error(error)
                            this.$toast.error('Une erreur est survenue')
                            this.loadingAddCropImg = false
                        }
                    } else {
                        this.$toast.error('Fichier trop volumineux')
                    }
                    this.loadingUpload = false
                },
                error(err) {
                    console.error(err)
                    this.$toast.error('Une erreur est survenue')
                    return file
                },
            });
        },

        addCropImage() {
            this.loadingAddCropImg = true
            var imgB64 = this.croppedCanva.toDataURL();
            fetch(imgB64).then(async (res) => {
                let img = await res.blob()
                this.compressFile(img)
            })
        },

        async changeHandler(data) {
            this.croppedCanva = data.canvas
        },

        deleteImg(imgUrl) {
            const index = this.form.images.indexOf(imgUrl);
            if (index > -1) {
                this.form.images.splice(index, 1)
            }
        },

        clickOnImage(event) {
            this.form.emplacement_x = event.offsetX
            this.form.emplacement_y = event.offsetY
        },

        dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;
        },

        async convertDraw() {
            let canvas = await html2canvas(document.querySelector("#draw-defaut"),
            {
                useCORS: true,
                onrendered: function (canvas) {}
            })
            var imageBase64 = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            let image = this.dataURItoBlob(imageBase64)
            return image
        }
    },
    mounted () {
        this.initData()
    },
}
</script>

<style lang="scss" scoped>

</style>