<template>
    <div>
        <app-template :header-dark="false" title="Historique des missions">
            <template v-slot:mobile>
                <div class="px-4">
                    <div class="rounded-xl bg-white p-4 text-center" v-if="!missions || missions.length <= 0">
                        <div class="text-xl font-semibold my-2">Aucune mission</div>
                        <button @click="$router.push({name: 'new-contract'})" class="px-4 py-2 bg-slate-100 rounded-2xl my-2"><i class="fas fa-plus mr-1"></i> Créer une fiche</button>
                    </div>
                    <div @click="$router.push({name: 'mission', params: {id: mission.id}})" v-for="(mission, missionIndex) in missions" :key="'mission-' + missionIndex" class="rounded-2xl mb-2 p-4 flex justify-between gap-2 items-center bg-white">
                        <div>
                            <div class="font-bold text-lg">{{ mission.marque + ' - ' + mission.model }}</div>
                            <div class="font-light text-sm mt-1">Ref. fiche: {{ mission.ref }}</div>
                            <div class="text-xs font-bold rounded-2xl mt-2 mb-1"><i class="fas fa-calendar mr-0.5"></i> 19/02/2024 - 15h30</div>
                            <div class="text-xs rounded-2xl mt-2 mb-1">{{ mission.etat_label }}</div>
                        </div>
                        <div>
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div>
                    <div v-if="missionRequest && missionRequest.next_page_url" class="text-center mt-4">
                        <button @click="loadMore" class="px-4 py-2 bg-slate-300 rounded-3xl text-sm">
                            Voir plus <i class="fas fa-plus ml-1"></i>
                        </button>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            missions: null,
            missionRequest: null
        }
    },
    methods: {
        async getMissions(url) {
            let response = await this.$request.post(url)
            this.missionRequest = response.data.api
            if (this.missions && this.missions.length > 0) {
                this.missions = this.missions.concat(this.missionRequest.data)
            }
        },

        loadMore() {
            this.getMissions('/mission/history?page=' + (this.missionRequest.current_page + 1));
        }
    },
    mounted () {
        this.getMissions('/mission/history')
    },
}
</script>

<style lang="scss" scoped>

</style>