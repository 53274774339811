<template>
    <div>
        <div class="px-4 py-5">
            <h1 class="text-xl font-bold mb-2 border-b pb-4 mb-4">Inscription</h1>
            <template v-if="!form.entreprise">
                <p class="bg-orange-100 text-sm p-2 rounded mb-2"><i class="fas fa-circle-info mr-1"></i> Afin de valider que vous êtes une société, nous sommes contraints de vous demandez votre numéro de SIRET.</p>
                <div class="bg-white p-4 rounded mb-2">
                    <input-rule v-model="form.siret" type="text" id="siret" label="Numéro de siret" />
                    <button @click="checkSiret" class="px-4 py-2 bg-sky-500 rounded-lg text-white text-sm mt-2">Vérifier</button>
                </div>
            </template>
            <template v-if="form.entreprise">
                <div class="bg-white p-4 rounded mb-2">
                    <div @click="displayEntrepriseData = !displayEntrepriseData" class="flex items-center justify-between gap-2">
                        <div class="font-bold">Informations entreprise</div>
                        <template v-if="!displayEntrepriseData"><i class="fas fa-chevron-right"></i></template>
                        <template v-if="displayEntrepriseData"><i class="fas fa-chevron-down"></i></template>
                    </div>
                    <div class="pt-2 mt-2 border-t border-slate-400" v-if="displayEntrepriseData">
                        <input-rule class="mb-4" v-model="form.entreprise.siret" type="text" id="siret" label="Siret" :disabled="true" />
                        <input-rule class="mb-4" v-model="form.entreprise.tva" type="text" id="tva" label="TVA intra." :disabled="true" />
                        <input-rule class="mb-4" v-model="form.entreprise.nom" type="text" id="entreprise-nom" label="Nom de l'entreprise" />
                        <input-rule class="mb-4" v-model="form.entreprise.adresse1" type="text" id="adresse1" label="Adresse" />
                        <input-rule class="mb-4" v-model="form.entreprise.cp" type="text" id="cp" label="Code postal" />
                        <input-rule class="mb-4" v-model="form.entreprise.ville" type="text" id="ville" label="Ville" />
                        <input-rule class="mb-4" v-model="form.entreprise.pays" type="text" id="pays" label="Pays" />
                    </div>
                </div>
                <div class="bg-white p-4 rounded mb-2">
                    <div class="font-bold mb-2">Compte utilisateur</div>
                    <input-rule class="mb-4" v-model="form.prenom" type="text" id="prenom" label="Prénom" />
                    <input-rule class="mb-4" v-model="form.nom" type="text" id="nom" label="Nom" />
                    <input-rule class="mb-4" v-model="form.email" type="email" id="email" label="Email" />
                    <input-rule class="mb-4" v-model="form.password" type="password" id="password" label="Mot de passe" />
                </div>
                <div class="text-center">
                    <button @click="register" class="px-6 py-3 bg-sky-500 rounded-lg text-white mt-2">S'inscrire <i class="fas fa-check ml-1"></i></button>
                </div>
            </template>
            <!-- <input-rule v-model="form.email" type="email" id="email" /> -->
            <!-- <input-rule v-model="form.password" type="password" id="password" /> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                siret: null,
                // entreprise: {"type":"siret","id":null,"nom":"ANWEILER VICTOR","forme_juridique":"Entrepreneur individuel","siret":"84359669300021","tva":"FR48843596693","adresse1":"650 RTE DU MOLARD","adresse2":null,"cp":"01160","ville":"SAINT-MARTIN-DU-MONT","longitude":5.307221,"latitude":46.095212,"pays":"FR"}
                entreprise: null,
            },
            displayEntrepriseData: false,
        }
    },
    methods: {
        async checkSiret() {
            if (!this.$validation.check([
                {value: this.form.siret, rules: 'required', id: 'siret'},
            ])) {return}

            let response = await this.$request.post('/auth/check-siret', {
                siret: this.form.siret
            })
            if (response.data.success == 0) {
                this.$toast.error(response.data.message)
                return
            }
            if (response.data.api == -1) {
                this.$toast.error('Siret introuvable')
                return
            } else {
                this.form.entreprise = response.data.api
            }
        },

        async register() {
            if (!this.$validation.check([
                {value: this.form.prenom, rules: 'required', id: 'prenom'},
                {value: this.form.nom, rules: 'required', id: 'nom'},
                {value: this.form.email, rules: 'required|email', id: 'email'},
                {value: this.form.password, rules: 'required', id: 'password'},
            ])) {return}

            if (!this.$validation.check([
                {value: this.form.entreprise.siret, rules: 'required', id: 'siret'},
                {value: this.form.entreprise.tva, rules: 'required', id: 'tva'},
                {value: this.form.entreprise.nom, rules: 'required', id: 'entreprise-nom'},
                {value: this.form.entreprise.adresse1, rules: 'required', id: 'adresse1'},
                {value: this.form.entreprise.cp, rules: 'required', id: 'cp'},
                {value: this.form.entreprise.ville, rules: 'required', id: 'ville'},
                {value: this.form.entreprise.pays, rules: 'required', id: 'pays'},
            ])) {
                this.$toast.error('Informations entreprise incorrectes')
                return
            }

            try {
                let response = await this.$request.post('/auth/register', this.form)
                if (response.data.success == 0) {
                    this.$toast.error(response.data.message)
                    return
                }
                if (response.data.api) {
                    localStorage.setItem('auth', JSON.stringify(response.data.api))
                    window.location = '/'
                } else {
                    this.$toast.error('Une erreur est survenue')
                }
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>