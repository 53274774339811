<template>
    <div>
        <modal name="modal-add-mission" height="auto" width="40%" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Ajouter une mission</h2>
                    <button @click="$modal.hide('modal-add-mission')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <new-contract />
                </div>
            </div>
        </modal>

        <app-template :header-dark="false" title="Accueil">
            <template v-slot:mobile>
                <div>
                    <!-- <div class="text-white p-6 pb-16" style="background: #161822">
                        <h1 class="font-black text-3xl mb-2">Accueil</h1>
                    </div> -->

                    <div class="px-4">
                        <div class="bg-blue-600 text-white rounded-2xl p-4 mb-3">
                            <div class="flex gap-2 items-center justify-between">
                                <div>
                                    <h2 class="font-light text-sm"><i class="fas fa-wallet mr-1"></i> Mon solde</h2>
                                    <h1 class="font-black text-3xl my-2">20 €</h1>
                                </div>
                                <div class="flex gap-2">
                                    <div class="text-center" @click="$router.push({name: 'solde'})">
                                        <button class="text-sm text-blue-600 bg-white bg-opacity-90 rounded-full px-3 py-2"><i class="fas fa-plus"></i></button>
                                        <div class="text-xs font-semibold mt-1">Recharger</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white rounded-2xl p-3 pt-4 pb-8 mb-3">
                            <div class="font-bold mb-2">Navigation</div>
                            <div class="grid grid-cols-4 gap-2">
                                <!-- <div class="text-center mt-2">
                                    <div class="bg-blue-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-file text-blue-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Fiche</div>
                                </div> -->
                                <div @click="$router.push({name: 'new-contract'})" class="text-center mt-2">
                                    <div class="bg-purple-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-file text-purple-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Fiche</div>
                                </div>
                                <div @click="$router.push({name: 'clients'})" class="text-center mt-2">
                                    <div class="bg-orange-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-users text-orange-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Clients</div>
                                </div>
                                <div @click="$router.push({name: 'entreprise'})" class="text-center mt-2">
                                    <div class="bg-sky-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-building text-sky-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Entreprise</div>
                                </div>
                                <div @click="$router.push({name: 'account'})" class="text-center mt-2">
                                    <div class="bg-yellow-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-user text-yellow-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Compte</div>
                                </div>
                                <!-- <div class="text-center mt-2">
                                    <div class="bg-pink-100 rounded-2xl py-2.5 w-2/3 mx-auto">
                                        <i class="fas fa-comments text-pink-700"></i>
                                    </div>
                                    <div class="text-xs font-semibold mt-2">Support</div>
                                </div> -->
                            </div>
                        </div>

                        <div class="font-bold mt-8 mb-3">Prochaines missions</div>
                        <div v-if="!missions || missions.length <= 0" class="rounded-xl bg-white p-4 text-center">
                            <div class="text-xl font-semibold my-2">Aucune mission planifiée</div>
                            <button @click="$router.push({name: 'new-contract'})" class="px-4 py-2 bg-slate-100 rounded-2xl my-2"><i class="fas fa-plus mr-1"></i> Créer une fiche</button>
                        </div>
                        <div @click="$router.push({name: 'mission', params: {id: mission.id}})" v-for="(mission, missionIndex) in missions" :key="'mission-' + missionIndex" class="rounded-2xl mb-2 p-4 flex justify-between gap-2 items-center bg-white">
                            <div>
                                <div class="font-bold text-lg">{{ mission.marque + ' - ' + mission.model }}</div>
                                <div class="font-light text-sm mt-1">Ref. fiche: {{ mission.ref }}</div>
                                <div class="text-xs font-bold rounded-2xl mt-2 mb-1"><i class="fas fa-calendar mr-0.5"></i> 19/02/2024 - 15h30</div>
                            </div>
                            <div>
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>

                        <div class="text-center mt-4">
                            <button @click="$router.push({name: 'mission-history'})" class="px-4 py-2 bg-slate-300 rounded-3xl text-sm">
                                Historique <i class="fas fa-clock-rotate-left ml-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:largeScreen>
                <div class="container mx-auto">
                    <h2 class="text-3xl font-base">Mes missions</h2>
                    <h4 class="font-light opacity-70 mb-4">{{ missions.length + ' ' + (missions.length > 1 ? 'missions' : 'mission') }}</h4>
                    <div class="bg-white rounded-lg shadow p-6 container mx-auto">
                        <div class="flex items-center justify-between mb-4">
                            <select v-model="state" @change="getMissions" class="border rounded py-1 px-2 focus:outline-none">
                                <option value="-1">Défaut</option>
                                <option value="0">Planifiée</option>
                                <option value="1">En cours</option>
                                <option value="2">Terminée</option>
                                <option value="3">Annulée</option>
                            </select>
                            <button @click="$modal.show('modal-add-mission')" class="px-4 py-2 text-sm font-light rounded bg-green-500 hover:bg-green-600 duration-200 text-white">Ajouter une mission <i class="fas fa-plus ml-1"></i></button>
                        </div>
                        <div class="text-center p-3 mt-4 bg-gray-100 rounded text-lg font-thin opacity-90" v-if="missions && missions.length <= 0">
                            Aucune mission
                        </div> 
                        <div class="relative overflow-x-auto">
                            <table v-if="missions.length > 0" class="w-full text-sm text-left rtl:text-right text-gray-500 border border-slate-100">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">Etat</th>
                                        <th scope="col" class="px-6 py-3">Marque</th>
                                        <th scope="col" class="px-6 py-3">Modèle</th>
                                        <th scope="col" class="px-6 py-3">Immatriculation</th>
                                        <th scope="col" class="px-6 py-3">Prix</th>
                                        <th scope="col" class="px-6 py-3">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="mission in missions">
                                        <tr class="bg-white border-b hover:bg-gray-50 duration-200">
                                            <td class="px-6 py-2 flex text-slate-900"><mission-state :state="mission.etat" /></td>
                                            <td class="px-6 py-2">{{ mission.marque }}</td>
                                            <td class="px-6 py-2">{{ mission.model }}</td>
                                            <td class="px-6 py-2">{{ mission.immatriculation }}</td>
                                            <td class="px-6 py-2">{{ mission.prix }}€</td>
                                            <td class="px-6 py-2">
                                                <button @click="$router.push({name: 'mission', params: {id: mission.id}})" class="px-4 py-2 bg-blue-500 hover:bg-blue-600 duration-200 rounded-full text-xs text-white">Consulter</button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import MissionState from './Components/MissionState.vue'
import NewContract from './Components/NewContract.vue'

export default {
    name: 'Home',
    components: {
        NewContract,
        MissionState,
    },
    data() {
        return {
            missions: [],
            // stats: null,
            state: -1,
        }
    },
    methods: {
        async getMissions() {
            let response = await this.$request.get('/mission/list?state=' + this.state)
            this.missions = response.data.api
        },

        // async getStats() {
        //     let response = await this.$request.get('/stats/home')
        //     this.stats = response.data.api
        // }
    },
    mounted () {
        // this.getStats()
        this.getMissions()
    },
}
</script>

<style>
</style>
