<template>
    <div>
        <vue-phone-number-input
        :default-country-code="options?.country"
        class="w-full bg-gray-50"
        size="sm"
        @update="telValidation"
        v-model="inpValue"
        :translations="{
            countrySelectorLabel: 'Code pays',
            countrySelectorError: 'Choisir un pays',
            phoneNumberLabel: 'Numéro de téléphone',
            example: 'Exemple'
        }"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        options: {},
    },

    watch: {
        value() {
            this.inputValue = this.value
        },
    },
    data() {
        return {
            inpValue: null,
        }
    },
    methods: {
        telValidation: function(value) {
            if (value.isValid) {
                let endValue = null
                if (value.countryCode == 'FR') {
                    endValue = value.formatNational
                } else {
                    endValue = value.formattedNumber
                }
                this.$emit('input', endValue)
            } else {
                this.$emit('input', null)
            }
        },
    },
    mounted () {
        this.inputValue = this.value
    },
}
</script>

<style lang="scss" scoped>

</style>
