<template>
    <div>

    </div>
</template>

<script>
export default {
    mounted () {
        localStorage.removeItem('account')
        this.$router.push({name: 'login'})
    },
}
</script>

<style lang="scss" scoped>

</style>