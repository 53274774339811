<template>
    <div>
        <app-template :header-dark="true" title="Mon compte">
            <template v-slot:mobile>
                <div class="text-white p-6 pt-8 pb-16 -mt-8" style="background: #161822"></div>

                <div class="px-4">
                    <div class="-mt-14 shadow bg-white rounded-xl mx-auto p-5">
                    <div class="h-[60px] w-[60px] mx-auto mb-2 rounded-full" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + $account.avatar + ')'"></div>
                        <h3 class="text-3xl font-bold text-slate-700 text-center">{{ $account.prenom + ' ' + $account.nom }}</h3>
                    </div>
                    <div class="font-bold mb-2 mt-6">Paramètres</div>
                    <div class="bg-white rounded-2xl p-3 pt-4 pb-4 mb-3">
                        <div class="mb-3 border-b pb-3">
                            <label class="block mb-0.5 text-sm font-medium text-gray-900">Avatar</label>
                            <input-rule v-model="formAccount.avatar" type="file" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2" />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="block mb-0.5 text-sm font-medium text-gray-900">Nouveau mot de passe</label>
                            <input v-model="formAccount.password" type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                        </div>
                        <div class="mb-3">
                            <label for="confirm-password" class="block mb-0.5 text-sm font-medium text-gray-900">Confirmer le mot de passe</label>
                            <input v-model="formAccount.confirmPassword" type="password" id="confirm-password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                        </div>
                        <button @click="updateAccount" class="px-4 py-2 rounded-3xl bg-sky-500 text-white text-sm mt-1 mb-2">Modifier</button>
                    </div>
        
                    <div class="font-bold mb-2 mt-6">Signature de l'employé</div>
                    <div class="bg-white rounded-2xl p-3 pt-4 pb-4 mb-3">
                        <div v-if="!$account.signature" class="mb-3">
                            <div class="p-4 mb-2 text-sm text-yellow-800 rounded-2xl bg-yellow-50" role="alert">
                                <span class="font-medium">Attention: </span> Une fois votre signature ajoutée, elle ne pourra plus être modifée
                                <hr class="my-2 border-yellow-800" />
                                Pensez à vérifier que la signature ne dépasse pas du cadre
                            </div>
                            <signature @input="uploadSignature" v-model="signature" />
                        </div>
                        <div v-if="$account.signature">
                            <img class="border rounded-lg h-[150px]" :src="$account.signature" />
                        </div>
                    </div>
                    <div class="text-center mt-5">
                        <button @click="$router.push({name: 'logout'})" class="px-5 py-3 rounded-3xl bg-slate-300 mt-1 mb-2">Déconnexion <i class="fa-solid fa-arrow-right-from-bracket ml-1"></i></button>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import Signature from '../../components/Signature.vue';

export default {
    components: {
        Signature,
    },
    data() {
        return {
            signature: null,
            formAccount: {}
        }
    },
    methods: {
        async updateAccount() {
            if (this.formAccount.password && this.formAccount.password != this.formAccount.confirmPassword) {
                this.$toast.error('Les mots de passe sont différents')
                return
            }
            let response = await this.$request.post('/auth/update', {
                password: this.formAccount.password,
                avatar: this.formAccount.avatar ? this.formAccount.avatar.url : ''
            })
            if (response.data.api == 1) {
                if (this.formAccount.avatar) {
                    await this.refreshAccount()
                    location.reload()
                } else {
                    this.$toast.success('Compte modifé')
                }
                this.formAccount = {}
            }
        },

        async refreshAccount() {
            if (localStorage.getItem('auth')) {
                let response = await this.$request.post('/auth/account')
                if (response.data.success) {
                    localStorage.setItem('auth', JSON.stringify(response.data.api))
                }
            }
        },

        async uploadSignature() {
            let response = await this.$request.post('/employe/add-signature', {
                signature: this.signature
            })
            if (response.data.api == 1) {
                this.$toast.success('Signature enregistrée')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>