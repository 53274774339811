<template>
    <div>
        <modal :name="'modal-edit-add-address-' + _uid" height="500px" width="98%" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Ajouter une adresse</h2>
                    <button @click="$modal.hide('modal-edit-add-address-' + _uid)"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <address-input v-model="newAddress" type="text" class="mb-3" />
                    <button @click="createAddress" class="text-white rounded bg-sky-500 px-4 py-2 mt-2 mb-3">Ajouter l'adresse <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </modal>

        <div class="relative">
            <label v-if="label" class="block mb-1 text-xs font-bold text-gray-900">{{ label }}</label>
            <div @click="searchAddress">
                <input :ref="_uid" class="text-sm rounded-lg grow w-full p-1.5 focus:outline-none focus:border-gray-400 border border-gray-300 text-gray-900 rounded" v-model="search" @input="searchAddress" />
            </div>
            <div v-if="displayList" class="text-lg shadow bg-white absolute left-0 right-0 z-20 p-2">
                <button @click="$modal.show('modal-edit-add-address-' + _uid)" class="px-4 py-2 bg-gray-100 rounded-lg">Ajouter une adresse</button>
                <div class="mt-2">
                    <div @click="selectAddress(address)" v-for="(address, addressIndex) in listAddresses" :key="'address-' + addressIndex" class="py-2 mb-2" :class="listAddresses.length - 1 > addressIndex ? 'border-b' : ''">
                        {{ address.complet }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
    props: {
        label: {},
        clientId: {required: true}
    },
    data() {
        return {
            newAddress: {},
            search: null,
            timer: null,
            listAddresses: [],
            selectedAddress: null,
            displayList: false
        }
    },
    methods: {
        searchAddress() {
            clearTimeout(this.timer)
            this.timer = setTimeout(async () => {
                let response = await this.$request.post('/adresse/search', {
                    client_id: this.clientId,
                    search: this.search
                })
                this.listAddresses = response.data.api
                this.displayList = true
            }, 400)
        },
        async createAddress() {
            let endObj = {...this.newAddress, client_id: this.clientId}
            let response = await this.$request.post('/adresse/edit-add', endObj)
            if (response.data.success) {
                this.$modal.hide('modal-edit-add-address-' + this._uid)
                this.$toast.success('Adresse ajoutée')
                this.selectAddress(response.data.api)
            }
        },
        selectAddress(address) {
            this.selectedAddress = address
            this.listAddresses = []
            this.search = this.selectedAddress.complet
            this.displayList = false
            this.$emit('input', this.selectedAddress)
        },
    },

    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss" scoped>

</style>