import EventHub from '@/eventHub';

function checkRule(rule, value) {
        const rulesTools = {
            required: () => {
                if (!value || value === null || value === '') {
                    return 'Champ obligatoire'
                }
            },

            tel: () => {
                var regex = new RegExp(/^(01|02|03|04|05|06|07)[0-9]{8}/gi);
                if (value && !regex.test(value)) {
                    return 'Numéro invalide'
                }
            },

            cp: () => {
                var regex = new RegExp(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/i);
                if (value && !regex.test(value)) {
                    return 'Code postal invalide'
                }
            },

            siret: () => {
                var regex = new RegExp(/\d{14}/g);
                if (value && !regex.test(value)) {
                    return 'Numéro de SIRET invalide, exemple: 12345678900011'
                }
            },

            num: () => {
                if (value && isNaN(value)) {
                    return 'Le champ doit être un chiffre'
                }
            },

            min: (nb) => {
                if (value && Number(value) < nb) {
                    return 'Minimum: ' + nb
                }
            },

            email: () => {
                if (value && !String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    return 'Email invalide'
                }
            },

            tvaintra: () => {
                var regex = new RegExp(/^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))/);
                if (value && !regex.test(value)) {
                    return 'Numéro TVA incorrect'
                }
            }
        }

        let checked = false
        if (rulesTools[rule]) {
            if (rule.split(':')[0] == 'min') {
                checked = rulesTools[rule.split(':')[0]](rule.split(':')[1]) || true
            } else {
                checked = rulesTools[rule]() || true
            }
        }
        return checked
}

function check(items) {
    let errorFound = false

    EventHub.$emit('clear-input-error')

    items.forEach((item) => {
        if (item.rules) {
            const rules = item.rules.split('|')
            rules.forEach((rule) => {
                let checkedRule = checkRule(rule, item.value)
                if (checkedRule !== true) {
                    errorFound = true
                    EventHub.$emit('validation-input-error', {
                        id: item.id,
                        error: checkedRule
                    })
                }
            })
        }
    })

    return !errorFound
}

var ValidationTool = {
    check
}

export default ValidationTool
