<template>
    <div class="p-4 py-10">
        <template v-if="data">
            <h1 class="text-3xl font-bold">Bienvenue {{ data.prenom }},</h1>
            <p class="font-light text-lg mt-2 mb-4">Afin de valider votre compte, merci de finaliser votre inscription.</p>

            <!-- password -->
            <div v-if="!data.password" class="mt-4 shadow bg-white rounded-xl p-5">                
                <div class="mb-3">
                    <label for="password" class="block mb-0.5 text-sm font-medium text-gray-900">Nouveau mot de passe</label>
                    <input v-model="newPassword.password" type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                </div>
                <div class="mb-3">
                    <label for="confirm-password" class="block mb-0.5 text-sm font-medium text-gray-900">Confirmer le mot de passe</label>
                    <input v-model="newPassword.confirmPassword" type="password" id="confirm-password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                </div>
                <button @click="addPassword" class="px-4 py-2 rounded bg-slate-500 text-white text-sm mt-1 mb-2">Modifier</button>
            </div>

            <!-- signature -->
            <div v-if="!data.signature && data.password" class="mb-3">
                <div class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                    <span class="font-medium">Attention: </span> Une fois votre signature ajoutée, elle ne pourra plus être modifée
                </div>
                <div class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                    Pensez à vérifier que la signature ne dépasse pas du cadre
                </div>
                <signature @input="uploadSignature" v-model="signature" />
            </div>

            <!-- entreprise -->
            <div v-if="!data.societe_id && data.signature" class="mb-3">
                <input-rule v-model="formEntreprise.nom" class="mt-4" type="text" label="Nom" />
                <div class="mt-4" v-if="formEntreprise.logo && !editLogo">
                    <label class="block mb-1 text-xs font-bold">Logo</label>
                    <img @click="editLogo = true" class="h-[50px]" :src="formEntreprise.logo" />
                </div>
                <div v-show="!formEntreprise.logo || editLogo">
                    <input-rule @input="(item) => {if (item) {formEntreprise.logo = item.url}}" class="mt-4" type="file" label="Logo" />
                </div>
                <input-rule v-model="formEntreprise.siret" class="mt-4" type="text" label="SIRET" />
                <input-rule v-model="formEntreprise.tva" class="mt-4" type="text" label="TVA" />
                <address-input v-model="formEntreprise.adresse" class="mt-4" :default="formEntreprise.complet" type="text" :manual="false" />
                <button @click="updateEntreprise" class="px-4 py-2 rounded bg-slate-500 text-white text-sm mt-2 mb-2">Modifier</button>
            </div>
        </template>
    </div>
</template>

<script>
import Signature from '../../components/Signature.vue';

export default {
    components: {
        Signature,
    },
    data() {
        return {
            data: null,
            newPassword: {},
            signature: null,
            formEntreprise: {}
        }
    },
    methods: {
        async getAccount() {
            let response = await this.$request.post('/auth/token-end-register', {
                token: this.$route.params.token
            })
            if (response.data.api.id) {
                this.data = response.data.api
                if (this.data.password && this.data.signature && this.data.societe_id) {
                    let responseAccount = await this.$request.get('/auth/login-by-token', {
                        headers: {
                            'auth-token': this.data.token
                        }
                    })
                    if (responseAccount.data.api) {
                        localStorage.setItem('auth', JSON.stringify(responseAccount.data.api))
                        window.location = '/'
                    }
                }
            } else {
                window.location = '/'
            }
        },

        async addPassword() {
            if (this.newPassword.password && this.newPassword.password != this.newPassword.confirmPassword) {
                this.$toast.error('Les mots de passe sont différents')
                return
            }
            let response = await this.$request.post('/auth/update', {
                password: this.newPassword.password,
            }, {
                headers: {
                    'auth-token': this.data.token
                }
            })
            if (response.data.api) {
                this.$toast.success('Mot de passe créé')
                this.getAccount()
            }
        },

        async uploadSignature() {
            let response = await this.$request.post('/employe/add-signature', {
                signature: this.signature
            }, {
                headers: {
                    'auth-token': this.data.token
                }
            })
            if (response.data.api == 1) {
                this.$toast.success('Signature enregistrée')
                this.getAccount()
            }
        },

        async updateEntreprise() {
            let response = await this.$request.post('/societe/edit-add', this.formEntreprise, {
                headers: {
                    'auth-token': this.data.token
                }
            })
            if (response.data.api.id) {
                this.$toast.success('Entreprise modifiée')
                this.getAccount()
            }
        },
    },
    mounted () {
        this.getAccount()
    },
}
</script>

<style lang="scss" scoped>

</style>