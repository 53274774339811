import axios from 'axios'

let account = localStorage.getItem('auth')

const instance = axios.create({
    // baseURL: 'http://127.0.0.1:8011/api/',
    baseURL: 'https://mes-trajets-api.va-net.fr/api/',
    timeout: 20000,
    headers: {'auth-token': account ? JSON.parse(account).token : null}
});


export default instance