<template>
    <div>
        <div class="p-2 mt-2" v-if="loadingUpload">
            <spinner :size="10" />
        </div>
        <input
        v-if="!loadingUpload"
        type="file"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-1.5"
        :id="labelToId"
        :placeholder="placeholder"
        :accept="fileType ? fileType : 'image/*,application/pdf'"
        @change="upload"
        ref="fileupload"
        :disabled="disabled"
        :style="imgButton ? 'display: none' : ''"
        >
        <div v-if="imgButton" class="flex gap-2">
            <button v-if="!(fileUrl && preview)" class="border rounded p-2 px-4 bg-gray-100" @click="openInputFile"><i class="fas fa-camera"></i></button>
    
            <spinner class="my-3" v-if="loadingUpload" :size="10" />

            <div class="flex gap-4 items-center" v-if="fileUrl && !loadingUpload && preview">
                <a :href="fileUrl" target="_blank">
                    <img class="h-[75px]" :src="fileUrl">
                </a>
                <button class="bg-red-500 text-white py-1 px-2 rounded" @click="removeFile">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Compressor from 'compressorjs';

export default {
    props: {
        label: {
            type: String,
        },
        classSrc: {
            type: String
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultData: {},
        id: {},
        fileType: {},
        keyword: {},
        preview: {default: true},
        autoreset: {default: false},
        imgButton: {default: false}
    },
    watch: {
        defaultData() {
            this.setDefaultData()
        }
    },
    data() {
        return {
            loadingUpload: false,
            fileUrl: null,
            fileData: null
        }
    },
    computed: {
        labelToId: function() {
            if (this.label) {
                /* eslint-disable */
                return this.label.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            }
            return this.id
        }
    },
    methods: {
        openInputFile() {
            document.getElementById(this.labelToId).click()
        },
        async uploadFile(fileInput) {
            if (fileInput) {
                var bodyFormData = new FormData();
                bodyFormData.append('file', fileInput);
                bodyFormData.append('key', this.keyword);

                this.fileUrl = null
                this.loadingUpload = true
                let response = await this.$request.post('/file/add', bodyFormData)
                this.fileData = response.data.api
                if (this.fileData) {
                    this.fileUrl = this.fileData.url
                    // Emit Parent
                    this.$emit('input', this.fileData)
                    if (this.autoreset) {
                        this.fileUrl = null
                        this.fileData = null
                        if (this.$refs.fileupload) {
                            this.$refs.fileupload.value = null;
                        }
                        this.loadingUpload = false
                        this.$forceUpdate()
                    }
                }
                this.loadingUpload = false
            } else {
                this.fileUrl = null
            }
        },

        compressFile(file) {
            if (!file) {
                return;
            }

            new Compressor(file, {
                quality: 0.5,
                convertSize: 0,
                convertTypes: ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'],

                success: async (result) => {
                    if (result.size < 5000000) {
                        await this.uploadFile(result)
                    } else {
                        this.$toast.error('Fichier trop volumineux')
                    }
                    this.loadingUpload = false
                },
                error(err) {
                    console.warn('Error image compression:');
                    console.log(err.message);
                    return file
                },
            });
        },

        async upload(event) {
            // Set data
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            // Compress image
            if (fileInput) {
                let isFileImage = (file) => {
                    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'];
                    return file && acceptedImageTypes.includes(file['type'])
                }
                if (isFileImage(fileInput)) {
                    this.compressFile(fileInput)
                    return
                }
            }

            // Api
            await this.uploadFile(fileInput)
        },

        removeFile() {
            this.fileUrl = null
            this.fileData = null
            this.$emit('input', null)
        },

        setDefaultData() {
            if (this.defaultData) {
                this.fileData = this.defaultData
                this.fileUrl = this.defaultData.url
            }
        }
    },
    mounted() {
        this.setDefaultData()
    }
}
</script>
