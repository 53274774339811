<template>
    <div>
        <app-template :header-dark="false" title="Nouvelle fiche">
            <template v-slot:mobile>
                <!-- <div class="text-white -mt-2 p-4 pb-7 pt-10" style="background: #161822">
                    <h2 class="text-2xl font-base text-center">Nouvelle fiche</h2>
                </div> -->
                <div class="px-4 pt-2">
                    <new-contract />
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import NewContract from './Components/NewContract.vue'
export default {
    components: {
        NewContract,
    },
}
</script>

<style lang="scss" scoped>

</style>