import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

/* --------------
    COMPONENTS
---------------*/

import AppTemplate from '@/views/app/Components/AppTemplate.vue'
Vue.component('app-template', AppTemplate)

import InputRule from '@/components/InputRule.vue'
Vue.component('input-rule', InputRule)

import AddressInput from '@/components/AddressInput.vue'
Vue.component('address-input', AddressInput)

import Spinner from '@/components/Spinner.vue'
Vue.component('spinner', Spinner)

import TelInput from '@/components/TelInput'
Vue.component('tel-input', TelInput)

/* --------------
    PACKAGES
---------------*/

// Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {});

// Modal
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// Checkbox
import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox)

// Image cropper
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
Vue.use(Cropper);

// Input tel
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

/* --------------
    VARIABLES
---------------*/

import ValidationTool from './tools/ValidationTool.js'
Vue.prototype.$validation = ValidationTool

import AccountTool from './tools/AccountTool.js'
Vue.prototype.$account = AccountTool

import apiService from './services/api.service'
Vue.prototype.$request = apiService

import EventHub from '@/eventHub';
Vue.prototype.$eventHub = EventHub


/* --------------
    APP
---------------*/

async function initApp() {
    if (localStorage.getItem('auth')) {
        let response = await apiService.post('/auth/account')
        if (response.data.success) {
            localStorage.setItem('auth', JSON.stringify(response.data.api))
        }
    }
    new Vue({
        router,
        render: h => h(App)
    }).$mount('#app')
};

initApp()