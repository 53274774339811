<template>
    <div>
        <app-template :header-dark="true">
            <template v-slot:mobile>
                <div class="text-white p-6 pt-8 pb-16" style="background: #161822"></div>

                <div class="px-4">
                    <div class="-mt-14 shadow border bg-white rounded-xl mx-auto p-5">
                    <div class="h-[60px] w-[60px] mx-auto mb-2 rounded-full" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + $account.avatar + ')'"></div>
                        <h3 class="text-3xl font-bold text-slate-700 text-center">{{ $account.prenom + ' ' + $account.nom }}</h3>
                    </div>

                    <div @click="$router.push({name: 'account'})" class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Mon compte</div>
                        <i class="fas fa-chevron-right"></i>
                    </div>

                    <!-- <div @click="$router.push({name: 'clients'})" class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Gestion des clients</div>
                        <i class="fas fa-chevron-right"></i>
                    </div> -->

                    <div @click="$router.push({name: 'entreprise'})" class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Mon entreprise</div>
                        <i class="fas fa-chevron-right"></i>
                    </div>

                    <div @click="$router.push({name: 'logout'})" class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between text-red-500">
                        <div>Déconnexion</div>
                        <i class="fas fa-chevron-right"></i>
                    </div>

                    <!-- <div class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Paramètres contrat</div>
                        <i class="fas fa-chevron-right"></i>
                    </div> -->

                    <!-- <div class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Facturation</div>
                        <i class="fas fa-chevron-right"></i>
                    </div> -->

                    <!-- <div @click="$router.push({name: 'support'})" class="mt-4 shadow border bg-white rounded-xl p-5 text-lg flex items-center justify-between">
                        <div>Support</div>
                        <i class="fas fa-chevron-right"></i>
                    </div> -->
                </div>

                <!-- <div class="mt-4 p-4 shadow border bg-white rounded-lg">
                    <h2 class="font-bold text-lg border-b pb-3 mb-3"><i class="fas fa-cog mr-1"></i> Paramètres</h2>

                    <div class="mb-3">
                        <label for="immat" class="block mb-0.5 text-sm font-medium text-gray-900">Nouveau mot de passe</label>
                        <input type="text" id="immat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                    <div class="mb-3">
                        <label for="immat" class="block mb-0.5 text-sm font-medium text-gray-900">Confirmer le mot de passe</label>
                        <input type="text" id="immat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                    <button class="px-4 py-2 rounded bg-slate-500 text-white text-sm mt-1 mb-2">Modifier</button>
                </div> -->

                <!-- <div class="mt-4 p-4 shadow border bg-white rounded-lg">
                    <h2 class="font-bold text-lg border-b pb-3 mb-3"><i class="fas fa-signature mr-1"></i> Signature de l'employé</h2>

                    <div v-if="!$account.signature" class="mb-3">
                        <div class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                            <span class="font-medium">Attention: </span> Une fois votre signature ajoutée, elle ne pourra plus être modifée
                        </div>
                        <div class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                            Pensez à vérifier que la signature ne dépasse pas du cadre
                        </div>
                        <signature @input="uploadSignature" v-model="signature" />
                    </div>
                    <div v-if="$account.signature">
                        <img class="border" :src="$account.signature" />
                    </div>
                </div> -->
                <!-- <div class="text-center mt-10">
                    <button class="px-4 py-2 rounded bg-red-500 text-white mt-1 mb-2">Déconnexion <i class="fa-solid fa-arrow-right-from-bracket ml-1"></i></button>
                </div> -->
            </template>
        </app-template>
    </div>
</template>

<script>
import Signature from '../../components/Signature.vue';
export default {
    components: {
        Signature
    },
    data() {
        return {
            signature: null
        }
    },
    methods: {
        async uploadSignature() {
            let response = await this.$request.post('/employe/add-signature', {
                signature: this.signature
            })
            if (response.data.api == 1) {
                this.$toast.success('Signature ajoutée')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>