<template>
    <div>
        <modal :name="'modal-edit-add-client-' + _uid" width="90%" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Créer un client</h2>
                    <button @click="$modal.hide('modal-edit-add-client-' + _uid)"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <!-- <input-rule v-model="newClient.societe" type="pcheck" class="mb-3" label="Société" /> -->
                    <input-rule v-model="newClient.prenom" id="prenom" type="text" class="mb-3" label="Prénom" />
                    <input-rule v-model="newClient.nom" id="nom" type="text" class="mb-3" label="Nom" />
                    <input-rule v-model="newClient.mail" id="mail" type="email" class="mb-3" label="Email" />
                    <input-rule v-model="newClient.tel" id="tel" type="tel" class="mb-3" label="Téléphone" />
                    <button @click="createClient" class="text-white rounded bg-emerald-600 px-4 py-2 mt-2 mb-3">Valider <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </modal>

        <div class="relative">
            <label v-if="label" class="block mb-1 text-xs font-bold text-gray-900">{{ label }}</label>
            <div class="flex items-center gap-2">
                <template v-if="(listClients && listClients.length > 0) || selectedClient">
                    <input-rule class="grow" v-model="selectedClient" type="vselect" @search="searchClient" :options="{values: listClients, label: 'nom_complet'}" :id="id" />
                    <button @click="$modal.show('modal-edit-add-client-' + _uid)" class="px-3 h-[34px] bg-gray-400 text-sm text-white rounded"><i class="fas fa-user-plus"></i></button>
                </template>
                <template v-if="(!listClients || listClients <= 0) && !selectedClient">
                    <button @click="$modal.show('modal-edit-add-client-' + _uid)" class="px-3 h-[34px] bg-gray-400 text-sm text-white rounded mt-1"><i class="fas fa-user-plus"></i> Créer un client</button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
    props: {
        label: {},
        id: {}
    },
    watch: {
        selectedClient() {
            this.selectClient()
        }
    },
    data() {
        return {
            newClient: {},
            timer: null,
            listClients: null,
            selectedClient: null,
        }
    },
    methods: {
        async initClientList() {
            let response = await this.$request.post('/client/search', {
                search: null
            })
            this.listClients = response.data.api
        },
        searchClient(inp) {
            if (typeof inp != 'string') {
                return
            }
            clearTimeout(this.timer)
            this.timer = setTimeout(async () => {
                let response = await this.$request.post('/client/search', {
                    search: inp
                })
                this.listClients = response.data.api
            }, 400)
        },
        async createClient() {
            if (!this.$validation.check([
                {value: this.newClient.prenom, rules: 'required', id: 'prenom'},
                {value: this.newClient.nom, rules: 'required', id: 'nom'},
                {value: this.newClient.mail, rules: 'required|email', id: 'mail'},
                {value: this.newClient.tel, rules: 'required', id: 'password'},
            ])) {return}

            let response = await this.$request.post('/client/edit-add', this.newClient)
            if (response.data.success) {
                this.newClient = {}
                this.$modal.hide('modal-edit-add-client-' + this._uid)
                this.$toast.success('Client créé')
                this.selectedClient = response.data.api
                this.selectClient()
                this.$eventHub.$emit('refresh-client-list')
            }
        },
        selectClient() {
            this.listClients = []
            this.$emit('input', this.selectedClient)
        }
    },

    directives: {
        ClickOutside
    },

    async mounted () {
        this.initClientList()

        this.$eventHub.$on('refresh-client-list', () => {
            this.initClientList()
        })
    },
}
</script>

<style lang="scss" scoped>

</style>