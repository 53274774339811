<template>
    <div>
        <div :class="labelInline ? 'flex items-center justify-between gap-3' : ''">
            <!-- LABEL -->
            <label v-if="label" :for="uniqueId" class="block mb-1 text-xs font-bold" :class="displayErrors && errors.length > 0 ? 'text-red-500' : 'text-gray-900'" v-html="label"></label>
            <!-- TYPES -->
            <!-- Text -->
            <input
            v-if="type == 'text'"
            :placeholder="placeholder"
            v-model="inpValue"
            @focusout="$emit('focusout')"
            @input="inpValue = (options && options.uppercase ? inpValue.toUpperCase() : inpValue)"
            type="text"
            :id="uniqueId"
            :disabled="disabled"
            class="text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400"
            :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b bg-white' : 'rounded-lg bg-white')"
            @focusin="$emit('focusin')"
            />

            <!-- Email -->
            <input
            v-if="type == 'email'"
            :placeholder="placeholder"
            v-model="inpValue"
            @focusout="$emit('focusout')"
            @input="inpValue = (options && options.uppercase ? inpValue.toUpperCase() : inpValue)"
            type="email"
            :id="uniqueId"
            :disabled="disabled"
            class="text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400"
            :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b bg-white' : 'rounded-lg bg-white')"
            @focusin="$emit('focusin')"
            />

            <!-- Tel -->
            <tel-input v-if="type == 'tel'" :options="options" v-model="inpValue" />

            <!-- Password -->
            <input
            v-if="type == 'password'"
            :placeholder="placeholder"
            v-model="inpValue"
            @focusout="$emit('focusout')"
            type="password"
            :id="uniqueId"
            :disabled="disabled"
            class="text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400"
            :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b bg-white' : 'rounded-lg bg-white')"
            @focusin="$emit('focusin')"
            />
    
            <!-- Textarea -->
            <textarea v-if="type == 'textarea'" :rows="options && options.rows ? options.rows : 5" v-model="inpValue" @focusout="$emit('focusout')" :id="uniqueId" :disabled="disabled" class="bg-white text-sm rounded-lg block w-full p-1.5 focus:outline-none focus:border-gray-400" :class="inputStyle" :placeholder="placeholder"></textarea>

            <!-- Number -->
            <input v-if="type == 'number'" v-model="inpValue" @focusout="$emit('focusout')" type="number" :id="uniqueId" :disabled="disabled" class="text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400" :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg bg-white')" />
    
            <!-- Date -->
            <input v-if="type == 'date'" v-model="inpValue" type="date" :min="options ? options.min : null" :max="options ? options.max : null" :id="uniqueId" :disabled="disabled" class="bg-white w-full text-sm rounded-lg grow p-1.5 focus:outline-none" :class="inputStyle" />
            <input v-if="type == 'datetime'" v-model="inpValue" type="datetime-local" :min="options ? options.min : null" :max="options ? options.max : null" :id="uniqueId" :disabled="disabled" class="bg-white w-full text-sm rounded-lg grow p-1.5 focus:outline-none" :class="inputStyle" />

            <!-- File -->
            <input-file v-if="type == 'file'" :class="this.errors.length > 0 && this.displayErrors ? 'border-2 border-red-500 text-red-500 rounded-xl' : 'text-gray-900'" :id="uniqueId" v-model="inpValue" :default-data="typeof inpValue == 'string' ? {url: inpValue} : (typeof inpValue == 'object' ? inpValue : null)" :keyword="options ? options.key : null" :file-type="options ? options.fileType : null" :preview="options && options.preview ? options.preview : true" :autoreset="options && options.autoreset ? options.autoreset : false" :img-button="options && options.imgButton ? options.imgButton : false" />

            <!-- Select -->
            <template v-if="type == 'select'">
                <select v-if="options && options.values" v-model="inpValue" :id="uniqueId"  :disabled="disabled" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none" :class="inputStyle">
                    <option v-for="(selectItem, selectItemIndex) in options.values" :key="uniqueId + '-select-' + selectItemIndex" :value="options.key ? selectItem[options.key] : selectItem">
                        {{ options.label ? selectItem[options.label] : selectItem }}
                    </option>
                </select>
            </template>

            <template v-if="type == 'vselect'">
                <v-select
                v-model="inpValue"
                :options="options && options.values ? options.values : []"
                :filterable="options.filterable != null ? options.filterable : true"
                class="grow"
                :label="options ? options.label : null"
                @search="(search) => {
                    $emit('search', search)
                }"
                >
                    <span slot="no-options" @click="$refs.select.open = false">
                        Aucun résultat
                    </span>
                </v-select>
            </template>

            <!-- Checkbox -->
            <input v-if="type == 'checkbox'" v-model="inpValue" type="checkbox" :id="uniqueId"  :disabled="disabled" class="bg-gray-50 text-sm rounded-lg p-2.5 focus:outline-none -mt-2 mr-2" :class="inputStyle" />

            <!-- Pcheck -->
            <template v-if="type == 'pcheck'">
                <div v-if="labelInline" class="border-b grow border-dotted border-gray-300"></div>
                <p-check v-model="inpValue" color="success" class="p-switch p-fill"></p-check>
            </template>

            <!-- ERRORS -->
            <ul v-if="displayErrors && errors.length > 0" class="text-xs list-disc ml-3 mt-2 text-red-500 font-light">
                <li v-for="(error, errorIndex) in errors" :key="uniqueId + '-error-' + errorIndex">
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import InputFile from './InputFile.vue'

export default {
    components: {
        InputFile,
    },

    props: {
        type: {required: true},
        label: {},
        value: {},
        options: {},
        displayErrors: {default: true},
        labelInline: {default: false},
        disabled: {default: false},
        custom: {},
        placeholder: {},
        id: {},
    },

    watch: {
        value: {
            deep: true,
            handler: function() {
                this.inpValue = this.value
            }
        },
        inpValue: {
            deep: true,
            handler: function() {
                this.emitValue()
            }
        }
    },

    data() {
        return {
            inpValue: null,
            errors: [],
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },

    computed: {
        uniqueId() {
            return 'car-input-' + this._uid
        },

        inputStyle() {
            return (this.errors.length > 0 && this.displayErrors ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900') + ' ' + (this.disabled ? 'cursor-not-allowed' : '') + ' ' + this.custom
        }
    },

    methods:{
        emitAddress(address) {
            this.inpValue = address
            this.$emit('get', address)
        },

        emitValue() {
            if (this.type == 'pcheck') {
                this.inpValue = (this.inpValue == true ? 1 : 0)
            } else {
                this.$emit('input', this.inpValue)
            }
        },
        removeDateRangePicker() {
            this.inpValue = {
                startDate: null,
                endDate: null,
            }
        }
    },

    mounted() {
        if (this.value && this.value.value) {
            this.inpValue = this.value.value
        } else {
            this.inpValue = this.value
        }
        this.emitValue()

        this.$eventHub.$on('validation-input-error', (error) => {
            if (error.id && this.id == error.id) {
                this.errors.push(error.error)
            }
        })
        this.$eventHub.$on('clear-input-error', () => {
            this.errors = []
        })
    }
}
</script>

<style lang="scss" scoped>

</style>