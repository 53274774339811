import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Home from '../views/app/Home.vue'
import Mission from '../views/app/Mission.vue'
import Other from '../views/app/Other.vue'
import Account from '../views/app/Account.vue'
import Entreprise from '../views/app/Entreprise.vue'
import Clients from '../views/app/Clients.vue'
import Login from '../views/app/Login.vue'
import Register from '../views/app/Register.vue'
import Logout from '../views/app/Logout.vue'
import NewContract from '../views/app/NewContractView.vue'
import Finalisation from '../views/app/Finalisation.vue'
import Support from '../views/app/Support.vue'
import AddSignature from '../views/app/AddSignature.vue'
import MissionHistory from '../views/app/MissionHistory.vue'
import Solde from '../views/app/Solde.vue'

var middlewareUserAuth = (to, from, next) => {
    if (!localStorage['auth']) {
        next({name: 'login'})
    }
    if (!JSON.parse(localStorage['auth']).signature && to.name != 'add-signature') {
        next({name: 'add-signature'})
    }
    next()
}

const routes = [
    {path: '/', name: 'home', component: Home, beforeEnter: middlewareUserAuth},
    {path: '/mission/historique', name: 'mission-history', component: MissionHistory, beforeEnter: middlewareUserAuth},
    {path: '/mission/:id', name: 'mission', component: Mission, beforeEnter: middlewareUserAuth},
    {path: '/autre', name: 'other', component: Other, beforeEnter: middlewareUserAuth},
    {path: '/compte', name: 'account', component: Account, beforeEnter: middlewareUserAuth},
    {path: '/entreprise', name: 'entreprise', component: Entreprise, beforeEnter: middlewareUserAuth},
    {path: '/clients', name: 'clients', component: Clients, beforeEnter: middlewareUserAuth},
    {path: '/connexion', name: 'login', component: Login},
    {path: '/inscription', name: 'register', component: Register},
    {path: '/deconnexion', name: 'logout', component: Logout},
    {path: '/finalisation/:token', name: 'finalisation', component: Finalisation},
    {path: '/nouveau-contrat', name: 'new-contract', component: NewContract},
    {path: '/support', name: 'support', component: Support},
    {path: '/signature', name: 'add-signature', component: AddSignature},
    {path: '/solde', name: 'solde', component: Solde},
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        // document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0)
    }
})

export default router
