<template>
    <div>
        <modal name="modal-edit-add-client" height="auto" width="98%" :scrollable="true">
            <div class="p-4 min-h-[450px]">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">
                        <template v-if="selectedLine && selectedLine.id">Modifier un client</template>
                        <template v-else>Ajouter un client</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-client')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <div class="grid grid-cols-3 text-sm mb-4">
                        <button @click="selectedTabModal = 'client'" class="px-4 py-2 rounded-l" :class="selectedTabModal == 'client' ? 'bg-slate-800 text-white font-bold' : 'bg-slate-100'">Client</button>
                        <button @click="selectedTabModal = 'adresse'" class="px-4 py-2 rounded-r" :class="selectedTabModal == 'adresse' ? 'bg-slate-800 text-white font-bold' : 'bg-slate-100'">Adresses</button>
                        <button @click="selectedTabModal = 'delete'" class="px-4 py-2 rounded-r" :class="selectedTabModal == 'delete' ? 'bg-slate-800 text-white font-bold' : 'bg-slate-100'">Supprimer</button>
                    </div>
                    <template v-if="selectedTabModal == 'client'">
                        <!-- <input-rule v-model="selectedLine.societe" type="checkbox" class="mb-3" label="Société" /> -->
                        <input-rule v-model="selectedLine.prenom" id="prenom" type="text" class="mb-3" label="Prénom" />
                        <input-rule v-model="selectedLine.nom" id="nom" type="text" class="mb-3" label="Nom" />
                        <input-rule v-model="selectedLine.mail" id="mail" type="text" class="mb-3" label="Email" />
                        <input-rule v-model="selectedLine.tel" id="tel" type="tel" class="mb-3" label="Téléphone" />
                        <button @click="createClient" class="text-white rounded bg-sky-500 px-4 py-2 mt-2 mb-3">
                            Enregistrer <i class="fas fa-check ml-1"></i>
                        </button>
                    </template>
                    <template v-if="selectedTabModal == 'adresse'">
                        <template v-if="selectedLine && selectedLine.id">
                            <address-input @input="(item) => {addAddress(item)}" :auto-reset="true" />
                            <div class="border-b py-2 grid grid-cols-3 items-center" v-for="(adresse, adresseIndex) in selectedLine.adresses" :key="'adresse-' + adresseIndex">
                                <div class="col-span-2">{{ adresse.complet }}</div>
                                <div class="text-end">
                                    <button @click="removeAdresse(adresse)" class="text-red-500"><i class="fas fa-trash"></i></button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="bg-orange-100 text-orange-800 p-4 rounded">
                                <i class="fas fa-exclamation-triangle mr-1"></i> Le client doit être enregistré pour lui attribuer des adresses
                            </div>
                        </template>
                    </template>
                    <template v-if="selectedTabModal == 'delete'">
                        <div class="text-lg bg-red-100 p-4 rounded-2xl">Souhaitez-vous réellement supprimer ce client ?</div>
                        <button @click="deleteClient" class="px-4 py-2 bg-red-500 text-white rounded mt-4">Supprimer <i class="fas fa-trash ml-1"></i></button>
                    </template>
                </div>
            </div>
        </modal>

        <app-template :header-dark="false" title="Mes clients">
            <template v-slot:mobile>
                <div class="px-4 mt-4">
                    <div class="pt-2 mb-4">
                        <div @click="selectedLine = {}; $modal.show('modal-edit-add-client')" class="bg-sky-500 text-white rounded-lg py-3 text-center">Ajouter un client <i class="fas fa-plus ml-1"></i></div>
                    </div>
                    <div class="font-bold mb-2">Mes clients</div>
                    <div class="bg-white rounded-2xl p-3 pt-4 pb-8 mb-3">
                        <div v-for="(client, clientIndex) in clientsRequest.data" :key="'client-' + clientIndex" :class="clientIndex < clientsRequest.data.length -1 ? 'border-b pb-2 mb-2' : ''">
                            <div class="flex items-center justify-between gap-2">
                                <div>
                                    <div>{{ client.prenom + ' ' + client.nom }}</div>
                                    <ul class="text-xs list-disc ml-4 font-light mt-0.5">
                                        <li v-for="(adresse, adresseIndex) in client.adresses" :key="'adresse-' + adresseIndex">{{ adresse.complet }}</li>
                                        <li v-if="client.adresses.length <= 0" class="text-xs font-light">Aucune adresse</li>
                                    </ul>
                                </div>
                                <div class="flex items-center gap-2">
                                    <button @click="selectedLine = client; selectedTabModal = 'client'; $modal.show('modal-edit-add-client')" class="px-4 py-2 bg-gray-200 rounded-3xl text-sm"><i class="fas fa-pen"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clientsRequest: [],
            selectedLine: {},
            selectedTabModal: 'client'
        }
    },
    methods: {
        async getClients() {
            let response = await this.$request.post('/client/list')
            this.clientsRequest = response.data.api
        },

        async createClient() {
            if (!this.$validation.check([
                {value: this.selectedLine.prenom, rules: 'required', id: 'prenom'},
                {value: this.selectedLine.nom, rules: 'required', id: 'nom'},
                {value: this.selectedLine.mail, rules: 'required|email', id: 'mail'},
                {value: this.selectedLine.tel, rules: 'required', id: 'password'},
            ])) {return}

            let response = await this.$request.post('/client/edit-add', this.selectedLine)
            if (response.data.success) {
                this.$modal.hide('modal-edit-add-client')
                this.$toast.success('Client créé')
                this.getClients()
            }
        },

        async removeAdresse(adresse) {
            let response = await this.$request.post('/adresse/delete', {
                adresse_id: adresse.id
            })
            if (response.data.api == 1) {
                await this.getClients()
                this.selectedLine = this.clientsRequest.data.find((item) => item.id == this.selectedLine.id)
            }
        },

        async addAddress(address) {
            if (address && address.complet) {
                address.client_id = this.selectedLine.id
                await this.$request.post('/adresse/edit-add', address)
                await this.getClients()
                this.selectedLine = this.clientsRequest.data.find((item) => item.id == this.selectedLine.id)
            }
        },

        async deleteClient() {
            await this.$request.post('/client/delete', {
                client_id: this.selectedLine.id
            })
            await this.getClients()
            this.$modal.hide('modal-edit-add-client')
        }
    },
    mounted () {
        this.getClients()
    },
}
</script>

<style lang="scss" scoped>

</style>