<template>
    <div>
        <app-template :header-dark="false" title="Mon solde">
            <template v-slot:mobile>
                <div class="px-4">
                    <div class="bg-blue-600 text-white rounded-2xl p-4 mb-3">
                        <div class="flex gap-2 items-center justify-between">
                            <div>
                                <h2 class="font-light text-sm"><i class="fas fa-wallet mr-1"></i> Mon solde</h2>
                                <h1 class="font-black text-3xl my-2">20 €</h1>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white rounded-2xl p-4 mb-3">
                        <input-rule class="mb-4 border-b-2 pb-6" v-model="amount" type="number" label="Quel montant souhaitez-vous créditer ?" />
                        <VCreditCard :trans="translations" @change="creditInfoChanged"/>
                        <div class="text-center mb-4">
                            <button class="px-4 py-2 bg-green-600 text-white rounded">Recharger <i class="fas fa-check ml-1"></i></button>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import VCreditCard from 'v-credit-card';
import 'v-credit-card/dist/VCreditCard.css';

export default {
    components: {
        VCreditCard,
    },
    data() {
        return {
            amount: null,
            translations: {
                name: {
                    label: 'Nom',
                    placeholder: ''
                },
                card: {
                    label: 'Numero de carte',
                    placeholder: '1234 5678 1234 5678'
                },
                expiration: {
                    label: "Expiration"
                },
                security: {
                    label: 'Code de sécurité',
                    placeholder: '123'
                }
            },
            formCard: {},
        }
    },
    methods: {
        creditInfoChanged(values) {
            this.formCard = values
        }
    },
}
</script>

<style lang="scss" scoped>

</style>