<template>
    <div>
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=sky&shade=600" alt="Your Company">
                <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Connectez-vous à votre compte</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div class="space-y-6">
                    <div>
                        <input-rule v-model="email" type="text" label="Adresse email" id="email" />
                    </div>

                    <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
                            <div class="text-sm">
                                <!-- <a href="#" class="font-semibold text-sky-600 hover:text-sky-500">Mot de passe oublié?</a> -->
                            </div>
                        </div>
                        <div class="mt-2">
                            <input-rule v-model="password" type="password" id="password" />
                        </div>
                    </div>

                    <div>
                        <button @click="login" class="flex w-full justify-center rounded-md bg-sky-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">Se connecter</button>
                        <button @click="$router.push({name: 'register'})" class="mt-4 flex w-full justify-center rounded-md bg-slate-300 px-3 py-2.5 text-sm font-semibold leading-6 shadow-sm hover:bg-sky-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">S'inscrire</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            email: null,
            password: null
        }
    },
    methods: {
        async login() {
            if (!this.$validation.check([
                {value: this.email, rules: 'required|email', id: 'email'},
                {value: this.password, rules: 'required', id: 'password'},
            ])) {return}

            let response = await this.$request.post('/auth/connect', {
                email: this.email,
                password: this.password
            })
            if (response.data.success) {
                localStorage.setItem('auth', JSON.stringify(response.data.api))
                window.location = '/'
            } else {
                this.$toast.error(response.data.message)
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>