<template>
    <div>
        <modal name="modal-delete-default" width="98%" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Supprimer le défaut</h2>
                    <button @click="$modal.hide('modal-delete-default')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div v-if="selectedLine" class="mt-3">
                    <div class="border bg-slate-100 rounded-lg p-2">
                        <div class="font-light text-lg">{{ selectedLine.description }}</div>
                        <div class="font-thin text-xs">{{selectedLine.face_label}}</div>
                        <div class="grid grid-cols-5 gap-4">
                            <div v-for="(img, imgIndex) in selectedLine.images" :key="'modal-img-' + imgIndex" class="h-[65px] w-[65px] rounded mt-2" :style="'background-image: url(' + img.url + ')'" style="background-size: cover; background-position: center center;"></div>
                        </div>
                        <button @click="deleteDefaut" class="mt-4 bg-red-400 rounded-xl text-white px-4 py-2"><i class="fas fa-times mr-1"></i> Supprimer</button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-add-signature" width="98%" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Signature</h2>
                    <button @click="$modal.hide('modal-add-signature')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mt-3">
                    <signature @input="getSignature" />
                </div>
            </div>
        </modal>

        <div v-if="(type == 'depart' && mission.fiche_depart) || (type == 'arrivee' && mission.fiche_arrivee)">
            <a target="_blank" :href="type == 'depart' ? mission.fiche_depart.pdf_url : mission.fiche_arrivee.pdf_url">
                <div class="py-2 text-blue-500 underline">
                    <i class="fa-solid fa-file mr-1"></i>
                    Consulter le fichier
                </div>
            </a>
            <!-- <a target="_blank" class="text-blue-500 underline" :href="mission.fiche_depart.pdf_url">Consulter le fichier</a> -->
        </div>
        <div v-else>
            <div class="border-b-2 pb-5 mb-3.5">
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <label for="date_depart" class="appearance-none block mb-0.5 text-sm font-medium text-gray-900">Date</label>
                        <input v-model="marqueLeDate" type="date" id="date_depart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                    <div>
                        <label for="heure_depart" class="appearance-none block mb-0.5 text-sm font-medium text-gray-900">Heure</label>
                        <input v-model="marqueLeHour" type="time" id="heure_depart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                </div>
            </div>
            <div class="border-b-2 mb-3.5">
                <div class="grid grid-cols-2 gap-2">
                    <div class="mb-3">
                        <label for="kilometrage_depart" class="block mb-0.5 text-sm font-medium text-gray-900">Kilométrage</label>
                        <input v-model="form.km" type="number" id="kilometrage_depart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                    <div class="mb-3">
                        <label for="photo_kilometrage_depart" class="block mb-0.5 text-sm font-medium text-gray-900">Photo kilométrage</label>
                        <!-- <input type="file" capture id="photo_kilometrage_depart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2"> -->
                        <input-rule @input="(value) => {form.km_img = value ? value.url : null}" type="file" :options="{fileType: 'image/*', preview: true}" />
                    </div>
                </div>
                <div v-if="mission.demande_jauge" class="grid grid-cols-2 gap-2">
                    <div>
                        <label for="kilometrage_depart" class="block mb-0.5 text-sm font-medium text-gray-900">Niveau de carburant</label>
                        <input v-model="form.carburant" type="text" id="kilometrage_depart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2">
                    </div>
                    <div>
                        <label for="photo_kilometrage_depart" class="block mb-0.5 text-sm font-medium text-gray-900">Photo de la jauge</label>
                        <input-rule @input="(value) => {form.carburant_img = value ? value.url : null}" type="file" :options="{fileType: 'image/*'}" />
                    </div>
                </div>
            </div>

            <div class="border-b-2 pb-5 mb-3.5">
                <!-- <h2 class="font-bold text-lg mb-2"><i class="fas fa-triangle-exclamation mr-1"></i> Défauts</h2> -->
                <div class="font-bold mb-2">Défauts</div>
                <modal-defaut :mission="mission" :type="type" @added="getDefauts" :data-id="selectedDefautId" />

                <div @click="selectedDefautId = defaut.id; $modal.show('modal-add-default')" v-for="(defaut, defautIndex) in defauts" :key="'defaut-' + defautIndex" class="rounded border mt-2 p-3">
                    <div class="flex items-center gap-2 justify-between">
                        <div>
                            <div class="font-light text-sm">{{ defaut.description }}</div>
                            <div class="font-thin text-xs">{{defaut.face_label}} - {{ defaut.images.length + ' ' + (defaut.images.length > 1 ? 'photos' : 'photo') }}</div>
                        </div>
                        <button @click.stop="selectedLine = defaut; $modal.show('modal-delete-default')" class="bg-red-400 rounded text-white px-2.5 py-1"><i class="fas fa-times"></i></button>
                    </div>
                </div>
            </div>

            <div class="border-b-2 pb-5 mb-3.5">
                <!-- <h2 class="font-bold text-lg mb-2"><i class="fas fa-signature mr-1"></i> Signature du client</h2> -->
                <div class="font-bold mb-2">Signature du client</div>
                <button v-if="!form.img_signature" @click="$modal.show('modal-add-signature')" class="px-4 py-2 bg-slate-300 rounded text-slate-900 text-sm">Ajouter une signature <i class="fas fa-signature ml-1"></i></button>
                <template v-if="form.img_signature">
                    <img class="w-full border" :src="form.img_signature" />
                    <button @click="form.signature = null; $modal.show('modal-add-signature')" class="bg-slate-100 px-4 py-2 rounded-xl mt-2"><i class="fa-solid fa-rotate-right mr-1"></i> Refaire la signature</button>
                </template> 
            </div>
            <div class="mt-4">
                <button @click="sendFiche" class="py-3 text-white shadow rounded-lg w-full bg-emerald-500">
                    <div class="flex justify-center">
                        <spinner v-if="loadingSendFiche" :size="10" color="#fff" />
                    </div>
                    <template v-if="!loadingSendFiche">
                        <template v-if="type == 'depart'">
                            Valider la prise en charge <i class="fas fa-check ml-1"></i>
                        </template>
                        <template v-if="type == 'arrivee'">
                            Terminer la mission <i class="fas fa-check ml-1"></i>
                        </template>
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import ModalDefaut from './ModalDefaut.vue'
import Signature from '../../../components/Signature.vue'

export default {
    components: {
        ModalDefaut,
        Signature
    },
    props: {
        mission: {required: true},
        type: {required: true},
    },
    data() {
        return {
            marqueLeDate: null,
            marqueLeHour: null,
            form: {
                type: null,
                marque_le: null,
                km: null,
                km_img: null,
                carburant: null,
                carburant_img: null,
                mission_id: null,
                img_signature: null,
            },
            defauts: [],
            selectedDefautId: null,
            selectedLine: null,

            loadingSendFiche: false,
        }
    },
    methods: {
        async sendFiche() {
            this.form.marque_le = moment(this.marqueLeDate + ' ' + this.marqueLeHour).format("YYYY-MM-DD HH:mm:ss")
            this.form.mission_id = this.$route.params.id
            this.loadingSendFiche = true
            let response = await this.$request.post('/fiche/add', this.form)
            if (response.data.success) {
                if (this.form.type == 'depart') {
                    this.$toast.success('Mission prise en charge')
                }
                if (this.form.type == 'arrivee') {
                    this.$toast.success('Mission terminée')
                }
            }
            this.loadingSendFiche = false
            this.$emit('refresh-mission')
        },

        async getDefauts() {
            let response = await this.$request.post('/defaut/list', {
                mission_id: this.mission.id,
                type: this.type
            })
            this.defauts = response.data.api
        },

        async deleteDefaut() {
            if (this.selectedLine) {
                let response = await this.$request.post('/defaut/delete', {
                    defaut_id: this.selectedLine.id
                })
                if (response.data.api == 1) {
                    this.getDefauts()
                    this.$modal.hide('modal-delete-default')
                    this.selectedLine = null
                }
            }
        },

        getSignature(signatureUrl) {
            this.form.img_signature = signatureUrl
            this.$modal.hide('modal-add-signature')
        },
    },
    mounted () {
        this.getDefauts()

        this.form.type = this.type

        this.marqueLeDate = moment().format('Y-MM-DD')
        this.marqueLeHour = moment().format('HH:mm')

    },
}
</script>

<style lang="scss" scoped>

</style>