<template>
    <div>
        <modal name="modal-view-doc" width="100%" height="500px" :scrollable="true">
            <div v-if="selectedDoc" class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Consulter un document</h2>
                    <button @click="$modal.hide('modal-view-doc')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <iframe :src="selectedDoc.url" class="mb-8" frameborder="0" width="100%" height="250px"></iframe>
                    <div class="text-center">
                        <a :href="selectedDoc.url" target="_blank" class="px-4 py-2 mt-10 bg-gray-100 rounded">Ouvrir dans un nouvel onglet</a>
                    </div>
                </div>
            </div>
        </modal>

        <app-template title="Fiche">
            <template v-slot:mobile>
                <div class="px-4 pb-2 sticky top-[58px]" style="background: #ecf1f4">
                    <div class="grid grid-cols-3 justify-between items-center gap-2 bg-white p-1 rounded-3xl">
                        <div @click="selectedTab = 'fiche'" class="text-center" :class="selectedTab == 'fiche' ? 'bg-sky-400 text-white font-bold px-4 py-2 text-sm rounded-2xl' : ''">Fiche</div>
                        <div @click="selectedTab = 'depart'" class="text-center" :class="selectedTab == 'depart' ? 'bg-sky-400 text-white font-bold px-4 py-2 text-sm rounded-2xl' : ''">Départ</div>
                        <div @click="selectedTab = 'arrivee'" class="text-center" :class="selectedTab == 'arrivee' ? 'bg-sky-400 text-white font-bold px-4 py-2 text-sm rounded-2xl' : ''">Arrivée</div>
                    </div>
                </div>
                <template v-if="mission && selectedTab == 'fiche'">
                    <div class="px-4 mt-2">
                        Référence: {{ mission.ref }}
                        <!-- <img class="mt-4 w-[80%] mx-auto" src="https://i.ibb.co/X49m6xT/images-1-removebg-preview.png" alt=""> -->
                        <div class="font-bold mt-2 mb-1">Véhicule</div>
                        <div class="p-4 bg-white shadow rounded-2xl">
                            <!-- <h1 class="font-bold text-gray-600"><i class="fas fa-car text-sm mr-1"></i> Véhicule</h1> -->
                            <div class="text-lg">{{ mission.marque + ' - ' + mission.model }}</div>
                            <div class="font-light">Immatriculation: {{ mission.immatriculation }}</div>
                            <div class="font-light">Châssis: {{ mission.chassis }}</div>
                            <!-- <div class="font-light">Jauge carburant: 30 %</div> -->
                        </div>
                        <div class="font-bold mt-4 mb-1">Départ</div>
                        <div class="shadow p-4 bg-emerald-50 rounded-2xl">
                            <div class="grid grid-cols-3 gap-4 mt-2">
                                <div class="border-r col-span-2">
                                    <div class=""><i class="fas fa-user mr-1 text-sm"></i> {{ mission.client_depart.nom_complet }}</div>
                                    <div class="font-light mt-1">{{ mission.adresse_depart.complet }}</div>
                                </div>
                                <div>
                                    <div class=""><i class="fas fa-calendar mr-1 text-sm"></i> Date</div>
                                    <div class="font-light">19/02/2024<br />17h30</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-4 mt-3">
                                <button class="px-4 py-2 bg-emerald-600 text-white rounded-2xl">GPS</button>
                                <button class="px-4 py-2 bg-emerald-600 text-white rounded-2xl">Fiche client</button>
                            </div>
                        </div>
                        <div class="font-bold mt-4 mb-1">Arrivée</div>
                        <div class="shadow p-4 bg-emerald-50 rounded-2xl">
                            <div class="grid grid-cols-3 gap-4 mt-2">
                                <div class="border-r col-span-2">
                                    <div class=""><i class="fas fa-user mr-1 text-sm"></i> {{ mission.client_arrivee.nom_complet }}</div>
                                    <div class="font-light mt-1">{{ mission.adresse_arrivee.complet }}</div>
                                </div>
                                <div>
                                    <div class=""><i class="fas fa-calendar mr-1 text-sm"></i> Date</div>
                                    <div class="font-light">19/02/2024<br />17h30</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-4 mt-3">
                                <button class="px-4 py-2 bg-emerald-600 text-white rounded-2xl">GPS</button>
                                <button class="px-4 py-2 bg-emerald-600 text-white rounded-2xl">Fiche client</button>
                            </div>
                        </div>
                        <div class="font-bold mt-4 mb-1">Documents</div>
                        <div class="shadow p-4 bg-white rounded-2xl">
                            <div v-if="mission.documents && mission.documents.length <= 0 && !mission.fiche_depart && !mission.fiche_arrivee">
                                Aucun document
                            </div>
                            <div class="flex items-center justify-between my-3 gap-4" v-for="(doc, docIndex) in mission.documents" :key="'doc-' + docIndex">
                                <div>{{ doc.type }}</div>
                                <div class="grow border-b border-dashed"></div>
                                <div class="flex items-center gap-2">
                                    <button class="bg-gray-200 rounded-2xl px-3.5 py-1.5" @click="selectedDoc = doc; $modal.show('modal-view-doc')"><i class="fas fa-eye"></i></button>
                                </div>
                            </div>
                            <div v-if="mission.fiche_depart && mission.fiche_depart.pdf_url" class="flex items-center justify-between gap-4">
                                <div>Coupon de départ</div>
                                <div class="grow border-b border-dashed"></div>
                                <div class="flex items-center gap-2">
                                    <button class="bg-gray-200 rounded-2xl px-3.5 py-1.5" @click="selectedDoc = {url: mission.fiche_depart.pdf_url}; $modal.show('modal-view-doc')"><i class="fas fa-eye"></i></button>
                                </div>
                            </div>
                            <div v-if="mission.fiche_arrivee && mission.fiche_arrivee.pdf_url" class="flex items-center justify-between mt-3 gap-4">
                                <div>Coupon d'arrivée</div>
                                <div class="grow border-b border-dashed"></div>
                                <div class="flex items-center gap-2">
                                    <button class="bg-gray-200 rounded-2xl px-3.5 py-1.5" @click="selectedDoc = {url: mission.fiche_arrivee.pdf_url}; $modal.show('modal-view-doc')"><i class="fas fa-eye"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="mission && selectedTab == 'depart'">
                    <div v-if="!loading" class="px-4 mt-2">
                        <div class="font-bold mt-4 mb-1">Coupon de départ</div>
                        <div class="bg-white p-4 border rounded-2xl shadow-lg">
                            <fiche @refresh-mission="getMission" type="depart" :mission="mission" />
                        </div>

                        <template v-if="mission.fiche_depart">
                            <div class="font-bold mt-4 mb-1">Renvoyer le dossier</div>
                            <div class="bg-white p-4 border rounded-2xl shadow-lg">
                                <!-- <div class="text-2xl font-black mb-2">Renvoyer le dossier</div> -->

                                <div class="grid grid-cols-2 gap-4 items-center">
                                    <div class="mt-1 mb-2 text-sm">Envoyer le dossier à <b>{{ mission.client_depart.mail }}</b></div>
                                    <div class="text-end">
                                        <button :disabled="loadingResend" @click="resendFolder(mission.fiche_depart.id)" class="px-4 py-2 bg-slate-200 rounded-3xl text-sm">
                                            <spinner v-if="loadingResend" :size="10" />
                                            <template v-if="!loadingResend">Renvoyer <i class="fas fa-share ml-1"></i></template>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="mission && selectedTab == 'arrivee'">
                    <div v-if="!loading" class="px-4 mt-4">
                        <div class="bg-orange-200 rounded-2xl p-4 text-orange-800 font-bold text-center" v-if="!mission.fiche_depart">
                            Vous devez d'abord créer le coupon de départ 
                        </div>
                        <div v-else>
                            <div class="font-bold mt-4 mb-1">Coupon d'arrivée</div>
                            <div class="bg-white p-4 border rounded-2xl shadow-lg">
                                <fiche @refresh-mission="getMission" type="arrivee" :mission="mission" />
                            </div>
                            <template v-if="mission.fiche_arrivee">
                                <div class="font-bold mt-4 mb-1">Renvoyer le dossier</div>
                                <div class="bg-white p-4 border rounded-2xl shadow-lg">
                                    <!-- <div class="text-2xl font-black mb-2">Renvoyer le dossier</div> -->

                                    <div class="grid grid-cols-2 gap-4 items-center">
                                        <div class="mt-1 mb-2 text-sm">Envoyer le dossier à <b>{{ mission.client_arrivee.mail }}</b></div>
                                        <div class="text-end">
                                            <button :disabled="loadingResend" @click="resendFolder(mission.fiche_arrivee.id)" class="px-4 py-2 bg-slate-200 rounded-3xl text-sm">
                                                <spinner v-if="loadingResend" :size="10" />
                                                <template v-if="!loadingResend">Renvoyer <i class="fas fa-share ml-1"></i></template>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>

            <template v-slot:largeScreen>
                <div v-if="mission" class="container mx-auto">
                    <div class="bg-white rounded-lg shadow p-6" v-if="mission">
                        <h2 class="text-3xl font-base mb-4">Détails de la mission</h2>

                        <div class="grid grid-cols-4 gap-4">
                            <div class="bg-white rounded-xl grow border mt-2">
                                <div class="font-bold leading-none text-slate-600 p-4 pb-0"><i class="fas fa-car mr-1"></i> Véhicule</div>
                                <ul class="list-disc ml-4 p-4 pt-2">
                                    <li>Marque: {{ mission.marque }}</li>
                                    <li>Modele: {{ mission.model }}</li>
                                    <li>Immatriculation: {{ mission.immatriculation }}</li>
                                </ul>
                            </div>
                            <div class="bg-white rounded-xl grow border mt-2">
                                <div class="font-bold leading-none text-slate-600 p-4 pb-0"><i class="fas fa-users mr-1"></i> Employés</div>
                                <ul class="list-disc ml-4 p-4 pt-2">
                                    <li>Conducteur: {{ mission.employe.nom_complet }}</li>
                                    <li>Donneur d'ordre: {{ mission.donneur_ordre.nom_complet }}</li>
                                </ul>
                            </div>
                            <div class="bg-white rounded-xl grow border mt-2">
                                <div class="font-bold leading-none text-slate-600 p-4 pb-0"><i class="fas fa-flag mr-1"></i> Départ</div>
                                <ul class="list-disc ml-4 p-4 pt-2">
                                    <li>Nom: {{ mission.client_depart.nom_complet }}</li>
                                    <li>Adresse: {{ mission.adresse_depart.complet }}</li>
                                </ul>
                            </div>
                            <div class="bg-white rounded-xl grow border mt-2">
                                <div class="font-bold leading-none text-slate-600 p-4 pb-0"><i class="fas fa-flag-checkered mr-1"></i> Arrivée</div>
                                <ul class="list-disc ml-4 p-4 pt-2">
                                    <li>Nom: {{ mission.client_arrivee.nom_complet }}</li>
                                    <li>Adresse: {{ mission.adresse_arrivee.complet }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="grid grid-cols-2 gap-4 mt-8">
                            <div>
                                <ol class="relative">
                                    <li class="pb-5 pl-4 border-s-2 -mt-2 flex items-center" :class="mission.fiche_depart ? 'border-slate-600' : 'border-gray-200'">
                                        <div class="absolute w-3 h-3 rounded-full -start-1.5 ml-[1px] border border-white" :class="mission.fiche_depart ? 'bg-slate-500' : 'bg-slate-500'"></div>
                                        <div class="px-4 my-4">
                                            <div>Etape 1 - Coupon de départ</div>
                                            <div class="mt-2" v-if="mission.fiche_depart">
                                                <a :href="mission.fiche_depart.pdf_url" target="_blank" class="text-sm px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded-xl font-light"><i class="fas fa-link"></i> Consulter le fichier</a>
                                            </div>
                                            <div class="mt-2" v-if="!mission.fiche_depart">
                                                <div class="text-lg"><i class="fas fa-hourglass-half mr-1"></i> En attente du coupon de départ</div>
                                                <div class="text-sm font-light">Seul le conducteur chargé de la mission peut générer le coupon de départ via son smartphone</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-if="mission.fiche_depart" class="pb-5 pl-4 border-s-2 -mt-2 flex items-center" :class="mission.fiche_arrivee ? 'border-slate-600' : 'border-gray-200'">
                                        <div class="absolute w-3 h-3 rounded-full -start-1.5 ml-[1px] border border-white" :class="mission.fiche_arrivee ? 'bg-slate-500' : 'bg-slate-500'"></div>
                                        <div class="px-4 my-4">
                                            <div>Etape 2 - Coupon d'arrivée</div>
                                            <div class="mt-2" v-if="mission.fiche_arrivee">
                                                <a :href="mission.fiche_arrivee.pdf_url" target="_blank" class="text-sm px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded-xl font-light"><i class="fas fa-link"></i> Consulter le fichier</a>
                                            </div>
                                            <div class="mt-2" v-if="!mission.fiche_arrivee">
                                                <div class="text-lg"><i class="fas fa-hourglass-half mr-1"></i> En attente de la fiche arrivée</div>
                                                <div class="text-sm font-light">Seul le conducteur chargé de la mission peut générer la fiche arrivée en accédant à celle-ci sur son mobile</div>
                                            </div>
                                        </div>
                                    </li>

                                    <li v-if="mission.fiche_arrivee" class="pb-5 pl-4 border-s-2 -mt-2 flex items-center" :class="mission.fiche_arrivee ? 'border-slate-600' : 'border-gray-200'">
                                        <div class="absolute w-3 h-3 rounded-full -start-1.5 ml-[1px] border border-white" :class="mission.fiche_arrivee ? 'bg-slate-500' : 'bg-slate-500'"></div>
                                        <div class="px-4 my-4">
                                            <div>Etape 3</div>
                                            <div class="mt-2 bg-green-200 px-4 py-2 rounded-xl text-sm"><i class="fas fa-check-circle mr-1"></i> Mission terminée</div>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <div class="sticky top-10">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import Fiche from './Components/Fiche.vue'

export default {
    components: {
        Fiche,
    },
    data() {
        return {
            mission: null,
            selectedTab: 'fiche',

            selectedDoc: null,

            loading: false,
            loadingResend: false,
        }
    },

    methods: {
        async getMission() {
            this.loading = true
            try {
                let response = await this.$request.get('/mission/' + this.$route.params.id + '/get')
                if (!response.data.api) {
                    this.$toast.error('Vous ne pouvez pas accéder à cette page')
                    this.$router.push({name: 'home'})
                }
                this.mission = response.data.api
                // if (this.mission.etat == 0) {
                //     this.form.type = 'depart'
                // }
                // if (this.mission.etat == 1) {
                //     this.form.type = 'arrivee'
                // }
            } catch (error) {
                if (error.response.status) {
                    this.$toast.error('Mission introuvable')
                    this.$router.push({name: 'home'})
                }
            }
            this.loading = false
        },

        async resendFolder(ficheId) {
            this.loadingResend = true
            let response = await this.$request.post('/fiche/resend', {
                fiche_id: ficheId
            })
            if (response.data.api == 1) {
                this.$toast.success('Email envoyé')
            }
            this.loadingResend = false
        }
    },

    mounted () {
        this.getMission()
    },
}
</script>

<style lang="scss" scoped>

</style>