<template>
    <div>
        <modal name="modal-edit-employe" height="auto" width="98%" :scrollable="true">
            <div v-if="selectedEmploye" class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">
                        <template v-if="!selectedEmploye.id">Ajouter un employé</template>
                        <template v-if="selectedEmploye.id">Modifier un employé</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-employe')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <input-rule v-model="selectedEmploye.prenom" type="text" label="Prénom" class="mb-4" />
                    <input-rule v-model="selectedEmploye.nom" type="text" label="Nom" class="mb-4" />
                    <input-rule v-model="selectedEmploye.email" type="email" label="Email" class="mb-4" />
                    <div class="flex justify-between gap-2">
                        <button @click="editAddEmploye" class="px-4 py-2 bg-green-500 rounded text-white">
                            <spinner v-if="loadingEditEmploye" :size="10" />
                            <template v-if="!loadingEditEmploye">Enregistrer</template>
                        </button>
                        <button v-if="selectedEmploye.id" @click="$modal.show('modal-confirm-delete-employe')" class="px-4 py-2 bg-slate-200 rounded text-sm"><i class="fas fa-lock mr-1"></i> Bloquer</button>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="modal-confirm-delete-employe" height="auto" width="98%" :scrollable="true">
            <div v-if="selectedEmploye" class="p-4">
                <div class="flex items-center justify-between gap-2 border-b pb-4 mb-4">
                    <h2 class="font-bold text-xl">Supprimer un employé</h2>
                    <button @click="$modal.hide('modal-confirm-delete-employe')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                <div class="mb-3">
                    <div class="font-bold bg-slate-100 p-4 rounded mb-4">Souhaitez-vous réellement désactiver le compte de {{ selectedEmploye.nom_complet }} ?</div>
                    <button @click="deleteEmploye" class="px-4 py-2 bg-red-500 rounded text-white"><i class="fas fa-check mr-1"></i> Confirmer</button>
                </div>
            </div>
        </modal>
        <app-template :header-dark="true" title="Entreprise">
            <template v-slot:mobile>
                <div class="text-white p-6 pt-8 pb-16 -mt-8" style="background: #161822"></div>

                <div class="px-4">
                    <div class="-mt-14 shadow bg-white rounded-xl mx-auto p-5">
                        <div class="h-[60px] w-[60px] mx-auto mb-2 rounded-full" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + $account.avatar + ')'"></div>
                        <h3 class="text-3xl font-bold text-slate-700 text-center">{{ $account.prenom + ' ' + $account.nom }}</h3>
                    </div>

                    <div class="mt-4 shadow bg-white rounded-xl">
                        <div class="p-5 flex items-center justify-between" :class="openFicheEntreprise ? 'pb-0' : ''" @click="openFicheEntreprise = !openFicheEntreprise">
                            <h2 class="text-lg">Fiche entreprise</h2>
                            <div>
                                <template v-if="!openFicheEntreprise">
                                    <i class="fas fa-chevron-right"></i>
                                </template>
                                <template v-if="openFicheEntreprise">
                                    <i class="fas fa-chevron-down"></i>
                                </template>
                            </div>
                        </div>
                        <div class="p-5 pt-0" v-if="openFicheEntreprise">
                            <!-- Nom -->
                            <input-rule v-model="form.nom" class="mt-4" type="text" label="Nom" />

                            <!-- Logo -->
                            <label class="mt-4 block mb-1 text-xs font-bold">Logo</label>
                            <img @click="editLogo = true" v-if="form.logo" class="mt-2 h-[50px]" :src="form.logo" />
                            <input-rule @input="(item) => {if (item) {form.logo = item.url}}" class="" type="file" :options="{fileType: 'image/*', key: 'logo'}" />

                            <!-- Siret -->
                            <input-rule v-model="form.siret" class="mt-4" type="text" label="SIRET" />

                            <!-- Tva -->
                            <input-rule v-model="form.tva" class="mt-4" type="text" label="TVA" />

                            <!-- Adresse -->
                            <address-input v-model="form.adresse" class="mt-4" :default="form.complet" type="text" :manual="false" />

                            <!-- Button valid -->
                            <button @click="updateEntreprise" class="px-4 py-2 rounded bg-slate-500 text-white text-sm mt-2 mb-2">Modifier</button>
                        </div>
                    </div>

                    <div class="mt-4 shadow bg-white rounded-xl">
                        <div class="p-5 flex items-center justify-between" :class="openEmployes ? 'pb-0' : ''" @click="openEmployes = !openEmployes">
                            <h2 class="text-lg">Employés</h2>
                            <div>
                                <template v-if="!openEmployes">
                                    <i class="fas fa-chevron-right"></i>
                                </template>
                                <template v-if="openEmployes">
                                    <i class="fas fa-chevron-down"></i>
                                </template>
                            </div>
                        </div>
                        <div class="p-5 pt-0" v-if="openEmployes">
                            <div class="mt-4 border-b">
                                <div v-for="(employe, employeIndex) in employes" :key="'employe-' + employeIndex" class="border-t py-4 flex items-center justify-between gap-2">
                                    <div class="flex items-center gap-2">
                                        <img class="w-[30px] h-[30px] rounded-full" :src="employe.avatar" />
                                        {{ employe.nom_complet }}
                                    </div>
                                    <button @click="selectedEmploye = employe; $modal.show('modal-edit-employe')" class="px-4 py-2"><i class="fas fa-pen"></i></button>
                                </div>
                            </div>
                            <button @click="addNewEmploye" class="px-4 py-3 rounded-lg mt-2 w-full bg-slate-200"><i class="fas fa-plus mr-1"></i> Ajouter un compte employé</button>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
                editLogo: false,
                openFicheEntreprise: false,
                openEmployes: false,
                employes: [],
                selectedEmploye: null,
                loadingEditEmploye: false,
            }
        },
        methods: {
            async updateEntreprise() {
                let response = await this.$request.post('/societe/edit-add', this.form)
                if (response.data.api.id) {
                    this.$toast.success('Entreprise modifiée')
                    this.getEntreprise()
                }
            },

            async getEntreprise() {
                let response = await this.$request.get('/societe/get?id=' + this.$account.societe.id)
                let societe = response.data.api
                this.form = {
                    id: societe.id,
                    nom: societe.nom,
                    logo: societe.logo,
                    siret: societe.siret,
                    tva: societe.tva,
                    adresse: null,
                    complet: societe.complet
                }
            },

            async getEmployes() {
                let response = await this.$request.get('/employe/list')
                this.employes = response.data.api
            },

            addNewEmploye() {
                this.selectedEmploye = {
                    nom: null,
                    prenom: null,
                    email: null,
                    societe_id: this.$account.societe_id
                }
                this.$modal.show('modal-edit-employe')
            },

            async editAddEmploye() {
                this.loadingEditEmploye = true
                let response = await this.$request.post('/employe/edit-add', this.selectedEmploye)
                if (response.data.api.id) {
                    if (this.selectedEmploye.id) {
                        this.$toast.success('Employé modifié')
                    } else {
                        this.$toast.success('Employé ajouté')
                    }
                    this.$modal.hide('modal-edit-employe')
                    this.getEmployes()
                }
                this.loadingEditEmploye = false
            },

            async deleteEmploye() {
                let response = await this.$request.post('/employe/delete', {
                    employe_id: this.selectedEmploye.id
                })
                if (response.data.api == 1) {
                    this.$toast.success('Employé supprimé');
                    this.$modal.hide('modal-confirm-delete-employe')
                    this.$modal.hide('modal-edit-employe')
                    this.getEmployes()
                }
            }
        },
        mounted () {
            this.getEntreprise()
            this.getEmployes()
        },
    }
</script>

<style lang="scss" scoped>

</style>