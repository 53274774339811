<template>
    <div>
        <app-template :header-dark="true" title="Support">
            <template v-slot:mobile>
                <div class="text-white p-6 py-8 -mt-8" style="background: #161822">
                    <h2 class="text-2xl font-base text-center">Support</h2>
                </div>

                <div class="p-6">
                    <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-100 mb-4" role="alert">
                        Vous pouvez nous contacter pour toutes questions ou préoccupations. Nous vous répondrons à l'adresse email suivante: {{ $account.email }}
                    </div>
                    
                    <div class="bg-white rounded p-4 pt-4 pb-3 mb-3">
                        <div class="font-bold mb-4 border-b-2 pb-3">Nous contacter</div>
                        <input-rule v-model="message" type="textarea" placeholder="Votre message.." class="block text-sm font-medium text-gray-900" :options="{rows: 8}" />
                        <button @click="sendMessage" class="text-white text-sm rounded-3xl px-6 py-3 mb-2 mt-2" style="background: #3CA674">Envoyer <i class="fas fa-check ml-1"></i></button>
                    </div>

                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: null
        }
    },
    methods: {
        async sendMessage() {
            let response = await this.$request.post('/support/send-message', {
                message: this.message
            })
            if (response.data.api && response.data.api == 1) {
                this.message = null
                this.$toast.success('Message envoyé')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>